import React, { useState } from "react";
import MainContext from "./MainContext";

// const baseUrl = 'http://localhost:5000';
// const baseUrl = 'http://18.116.199.186/'
// const baseUrl='https://diabolo.onrender.com';
// const baseUrl = 'https://dolibo-backend.onrender.com';
// const baseUrl = "https://dolibobackend.madfishdev.com";
const baseUrl = "https://dev.dolibo.com";

const MainState = (props) => {
  const [user, setUser] = useState({})

  const login = async ({ email, password }) => {
    const resp = await fetch(`${baseUrl}/user/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    const data = await resp.json();
    // setUser(data);
    return data;
  };

  const register = async ({ name, phone, email, password }) => {
    const resp = await fetch(`${baseUrl}/user/signin`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ name, phone, email, password })
    });
    const data = await resp.json();
    return data;
  };

  const getUsers = async (id,query, page, perPage) => {
    const resp = await fetch(`${baseUrl}/user/getUsers?id=${id}&query=${query}&page=${page}&perPage=${perPage}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const updateUser = async ({ userId, name, email, designation, bio, country, state, file, password }) => {
    let formdata=new FormData();
    formdata.append('name', name);
    formdata.append('email', email);
    formdata.append('designation', designation);
    formdata.append('bio', bio);
    formdata.append('country', country);
    formdata.append('state', state);
    formdata.append('file', file);
    formdata.append('password', password);

    const resp = await fetch(`${baseUrl}/user/updateUser/${userId}`, {
      method: 'PUT',
      headers: {
        'token': localStorage.getItem('dolibo_token')
      },
      body: formdata
    });
    const data = await resp.json();
    console.log(data);
    return data;
  };

  const getProjects = async (id, query, page, perPage) => {
    const resp = await fetch(`${baseUrl}/project/getProjects?id=${id}&query=${query}&page=${page}&perPage=${perPage}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  // const getYoutubeChannel = async(ytChannelUrl,url,channelId) =>{
  //   const resp = await fetch(`${baseUrl}/builder/getYoutubeData?id=${channelId}&url=${url}&ytChannelUrl=${ytChannelUrl}`,{
  //      method:"GET",
  //      headers: {
  //       'content-type': 'application/json',
  //       'token': localStorage.getItem('dolibo_token')
  //     }
  //   });
  //   const data = await resp.json();
  //   return data;
  // }

  const postProject = async ({ title, file, desc }) => {
    const resp = await fetch(`${baseUrl}/project/postProject`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title, file, desc })
    });
    const data = await resp.json();
    return data;
  };

  const addProjectToFav = async ({ projectId, type }) => {
    const resp = await fetch(`${baseUrl}/user/addProjectToFav`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ projectId, type })
    });
    const data = await resp.json();
    return data;

  };

  const uploadImage = async ({ file }) => {
    let formdata = new FormData();
    for (let i of file) {
      formdata.append('file', i);
    }

    const resp = await fetch(`${baseUrl}/project/uploadImage`, {
      method: 'POST',
      headers: {
        'token': localStorage.getItem('dolibo_token')
      },
      body: formdata
    });
    const data = await resp.json();
    return data;
  };

  const updateProjectStatus = async ({ id, status }) => {
    const resp = await fetch(`${baseUrl}/project/updateProjectStatus/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ status })
    });
    const data = await resp.json();
    return data;
  };

  const updateUserStatus = async ({ id, status }) => {
    const resp = await fetch(`${baseUrl}/user/updateUserStatus/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ status })
    });

    const data = await resp.json();
    return data;
  }

  const updateProject = async ({ id, title, desc, file, status, isFavorite }) => {
    const resp = await fetch(`${baseUrl}/project/updateProject/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title, desc, file, status, isFavorite })
    });
    const data = await resp.json();
    return data;
  };

  const deleteProject = async (id) => {
    const resp = await fetch(`${baseUrl}/project/deleteProject/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const deleteUser = async (id) => {
    const resp = await fetch(`${baseUrl}/user/deleteUser/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const deleteProjectImage = async (id) => {
    id = id.replaceAll('/', ':');
    const resp = await fetch(`${baseUrl}/project/deleteProjectImage/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const getComponents = async (projectId) => {
    const resp = await fetch(`${baseUrl}/component/getComponents?projectId=${projectId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const postComponent = async ({ title, project }) => {
    const resp = await fetch(`${baseUrl}/component/postComponent`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title, project })
    });
    const data = await resp.json();
    return data;
  };

  const updateComponent = async ({ id, title }) => {
    const resp = await fetch(`${baseUrl}/component/updateComponent/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title })
    });
    const data = await resp.json();
    return data;
  };

  const deleteComponent = async (id) => {
    const resp = await fetch(`${baseUrl}/component/deleteComponent/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const getSubComponents = async (componentId) => {
    const resp = await fetch(`${baseUrl}/subComponent/getSubComponents?componentId=${componentId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const postSubComponent = async ({ title, project, component }) => {
    const resp = await fetch(`${baseUrl}/subComponent/postSubComponent`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title, project, component })
    });
    const data = await resp.json();
    return data;
  };

  const updateSubComponent = async ({ id, title }) => {
    const resp = await fetch(`${baseUrl}/subComponent/updateSubComponent/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ title })
    });
    const data = await resp.json();
    return data;
  };

  const deleteSubComponent = async (id) => {
    const resp = await fetch(`${baseUrl}/subComponent/deleteSubComponent/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const getFiles = async (projectId) => {
    const resp = await fetch(`${baseUrl}/file/getFiles?projectId=${projectId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const saveToggleState = async ({ toggle, project, cartesianArray }) => {
    const resp = await fetch(`${baseUrl}/file/saveToggleState`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ toggle, project, cartesianArray })
    });
    const data = await resp.json();
    return data;

  };

  const postFile = async ({ files, project }) => {
    const resp = await fetch(`${baseUrl}/file/postFile`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ files, project })
    });
    const data = await resp.json();
    return data;
  };

  const uploadFile = async ({ project, fileName, index, file }) => {
    let formdata = new FormData();
    formdata.append('project', project);
    formdata.append('fileName', fileName);
    formdata.append('index', index);
    formdata.append('file', file);

    const resp = await fetch(`${baseUrl}/file/uploadFile`, {
      method: 'POST',
      headers: {
        'token': localStorage.getItem('dolibo_token')
      },
      body: formdata
    });
    const data = await resp.json();
    return data;
  };

  const deleteUploadFile = async ({ project, fileName, index, id }) => {
    const resp = await fetch(`${baseUrl}/file/deleteUploadFile`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ project, fileName, index, id })
    });
    const data = await resp.json();
    return data;
  };

  const verify = async () => {
    const resp = await fetch(`${baseUrl}/user/verify`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const sendOtp = async ({ email }) => {
    const resp = await fetch(`${baseUrl}/user/sendOtp`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ email })
    });
    const data = await resp.json();
    // console.log(data);
    return data;
  };

  const submitOtp = async ({ otp, otp1 }) => {
    const resp = await fetch(`${baseUrl}/user/submitOtp`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ otp, otp1 })
    });
    const data = await resp.json();
    return data;
  };

  const changePassword = async ({ email, password }) => {
    const resp = await fetch(`${baseUrl}/user/changePassword`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ email, password })
    });
    const data = await resp.json();
    return data;
  };

  const promoteUser = async ({ id }) => {
    const resp = await fetch(`${baseUrl}/user/promoteUser/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({  })
    });
    const data = await resp.json();
    return data;
  };

  const demoteUser = async ({ id }) => {
    const resp = await fetch(`${baseUrl}/user/demoteUser/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({  })
    });
    const data = await resp.json();
    return data;
  };
  
  const getBuilders = async (projectId) => {
    const resp = await fetch(`${baseUrl}/builder/getBuilders?projectId=${projectId}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const postBuilder = async ({ project, file, data, youtubeFlag }) => {
    const resp = await fetch(`${baseUrl}/builder/postBuilder`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ project, file, data, youtubeFlag })
    });
    const data1 = await resp.json();
    return data1;
  };

  const updateBuilder = async ({ id, project, file, data }) => {
    const resp = await fetch(`${baseUrl}/builder/updateBuilder/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ project, file, data })
    });
    const data1 = await resp.json();
    return data1;
  };

  const deleteBuilder = async (id) => {
    const resp = await fetch(`${baseUrl}/builder/deleteBuilder/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };
  
  const resetPassword = async ({ userId, password }) => {
    const resp = await fetch(`${baseUrl}/user/resetPassword/${userId}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ password })
    });
    const data = await resp.json();
    return data;
  };
    
  const getUserBuilders = async (projectId, userId, id, comb) => {
    const resp = await fetch(`${baseUrl}/userBuilder/getUserBuilders?projectId=${projectId}&userId=${userId}&id=${id}&comb=${comb}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const postUserBuilder = async ({ project, data, builder, inputs, combination, files3, projectName1, files2, userBuilder, ytInfo, youtubeData }) => {
    const resp = await fetch(`${baseUrl}/userBuilder/postUserBuilder`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ project, data, builder, inputs, combination, files3, projectName1, files2, userBuilder, ytInfo, youtubeData })
    });
    const data1 = await resp.json();
    return data1;
  };

  const updateUserBuilder = async ({ id, data, status="false", inputs, combination, project }) => {
    const resp = await fetch(`${baseUrl}/userBuilder/updateUserBuilder/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      },
      body: JSON.stringify({ data, status, inputs, combination, project })
    });
    const data1 = await resp.json();
    return data1;
  };

  const deleteUserBuilder = async (id) => {
    const resp = await fetch(`${baseUrl}/userBuilder/deleteUserBuilder/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  return (
    <MainContext.Provider value={{ login, register, getProjects, postProject, updateProject, deleteProject, deleteProjectImage, uploadImage, updateProjectStatus, getComponents, postComponent, updateComponent, deleteComponent, getSubComponents, postSubComponent, updateSubComponent, deleteSubComponent, postFile, uploadFile, deleteUploadFile, verify, saveToggleState, getFiles, sendOtp, submitOtp, changePassword, getUsers, updateUserStatus, user, setUser, updateUser, deleteUser, promoteUser, demoteUser, getBuilders, postBuilder, updateBuilder, deleteBuilder, resetPassword, getUserBuilders, postUserBuilder, updateUserBuilder, deleteUserBuilder, addProjectToFav }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainState;
