// import { data } from 'jquery';
import React from 'react';
import { ReactFormGenerator, ElementStore } from 'react-form-builder2';

export default class Test1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    };

    this._onUpdate = this._onChange.bind(this);
    // console.log(data);
  }

  componentDidMount() {
    ElementStore.subscribe(state => this._onUpdate(state.data));
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  showShortPreview() {
    this.setState({
      shortPreviewVisible: true,
    });
  }

  showRoPreview() {
    this.setState({
      roPreviewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    });
  }

  _onChange(data) {
    // console.log(data);
    data=[
      {
        "id": "5D4C90B4-04F3-47F1-BC0B-2A09A0DD74AF",
        "name": "text_input_CE6AFEFD-44B4-460C-8648-F7A6C14368B3",
        "custom_name": "text_input_CE6AFEFD-44B4-460C-8648-F7A6C14368B3",
        "value": ""
      },
      {
        "id": "18A7776C-B41E-4391-9C0F-8AF1FA196CA1",
        "name": "email_input_6C433E74-2625-42E2-AD7F-39233DC3C47E",
        "custom_name": "email_input_6C433E74-2625-42E2-AD7F-39233DC3C47E",
        "value": ""
      }
    ];
    this.setState({
      data,
    });
  }

  _onSubmit(data) {
    data=[
      {
        "id": "5D4C90B4-04F3-47F1-BC0B-2A09A0DD74AF",
        "name": "text_input_CE6AFEFD-44B4-460C-8648-F7A6C14368B3",
        "custom_name": "text_input_CE6AFEFD-44B4-460C-8648-F7A6C14368B3",
        "value": ""
      },
      {
        "id": "18A7776C-B41E-4391-9C0F-8AF1FA196CA1",
        "name": "email_input_6C433E74-2625-42E2-AD7F-39233DC3C47E",
        "custom_name": "email_input_6C433E74-2625-42E2-AD7F-39233DC3C47E",
        "value": ""
      }
    ];
    this.setState({
      data,
    });
    console.log('onSubmit', data);
    // console.log(this.state);
    // Place code to post json data to server here
  }

  render() {
    let modalClass = 'modal';
    if (this.state.previewVisible) {
      modalClass += ' show d-block';
    }

    let shortModalClass = 'modal short-modal';
    if (this.state.shortPreviewVisible) {
      shortModalClass += ' show d-block';
    }

    let roModalClass = 'modal ro-modal';
    if (this.state.roPreviewVisible) {
      roModalClass += ' show d-block';
    }

    return (
      <div className="clearfix" style={{ margin: '10px', width: '70%' }}>
        <h4 className="float-left">Preview</h4>
        <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={this.showPreview.bind(this)}>Preview Form</button>
        <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={this.showShortPreview.bind(this)}>Alternate/Short Form</button>
        <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={this.showRoPreview.bind(this)}>Read Only Form</button>

        {this.state.previewVisible &&
          <div className={modalClass}>
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  back_action="#"
                  //   pehle slash tha
                  back_name="Back"
                  answer_data={{}}
                  action_name="Save"
                  form_action="/"
                  form_method="POST"
                  onSubmit={this._onSubmit}
                  variables={this.props.variables}
                  data={this.state.data} />

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }

        {this.state.roPreviewVisible &&
          <div className={roModalClass}>
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  back_action="/"
                  back_name="Back"
                  answer_data={{}}
                  action_name="Save"
                  form_action="/"
                  form_method="POST"
                  read_only={true}
                  variables={this.props.variables}
                  hide_actions={true} data={this.state.data} />

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }

        {this.state.shortPreviewVisible &&
          <div className={shortModalClass}>
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  back_action=""
                  answer_data={{}}
                  form_action="/"
                  form_method="POST"
                  data={this.state.data}
                  display_short={true}
                  variables={this.props.variables}
                  hide_actions={false} />

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
