import React, { useState, useEffect } from 'react'
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import poll from '../../../images/poll.png';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useMain } from '../../../hooks/useMain';

const CatalogDetail = ({ notify }) => {
  const navigate = useNavigate();
  let { getProjects, getUserBuilders } = useMain();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [flag1, setFlag1] = useState(false);
  const [comb, setComb] = useState("");
  const [loadFlag, setLoadFlag] = useState(true);
  console.log(data);

  useEffect(() => {
    getData();
  }, [id])

  const getData = async () => {
    let ans = await getProjects(id);
    let ans2 = await getUserBuilders('', JSON.parse(localStorage.getItem("dolibo_user"))._id);
    console.log(ans2);
    console.log(ans.data[0])
    setData(ans.data[0]);
    if(ans2 && ans2.data.length>0)
    {
      let t=ans2.data.find(x=>x.project===ans.data[0]._id);
      if(t)
      {
        setFlag1(true);
        setComb(t.combination);
      }
    }
  };

  const handleClick = async () => {
    if(flag1)
    {
      navigate(`/end-user/build-project/${id}/${comb}`);
    }
    else
    {
      navigate(`/end-user/build-project/${id}`);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                      <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Project Catelog
                      </h1>
                    </div>
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="catalog_details">
                      <div className="col-md-12">
                        {/* <div className="row">
                          {data.defaultImg === "false" ? data.img.map((e, index) => {
                            return (
                              <div key={index} className="col-md-3 cata_lok">
                                <div className="catalog_box">
                                  <div className="catalog_box_img">
                                    <img width="269" height="151" src={e.url} alt="" />
                                  </div>
                                </div>
                              </div>
                            )
                          }) : <div className="col-md-3 cata_lok">
                            <div className="catalog_box">
                              <div className="catalog_box_img">
                                <img width="269" height="151" src={poll} alt="" />
                              </div>
                            </div>
                          </div>}
                        </div> */}

                        <div className="row">
                          {data?.img?.map((e, index) => {
                            return (
                              <div key={index} className="col-md-3 cata_lok">
                                <div className="catalog_box">
                                  <div className="catalog_box_img">
                                    <img width="269" height="151" src={e.url} alt="" />
                                  </div>
                                </div>
                              </div>
                            )
                          })  }

                        </div>

                        <div className="row mt-10">
                          <div className="col-md-10 detr_sus">
                            <div className="detr">
                              <h3>{data?.title}</h3>
                              <span> Created by: {data?.createdBy?.name}</span>
                              <p>{data?.desc?.simpleText}</p>
                              <div className="cata_btn">
                                <div onClick={() => {
                                  if (!loadFlag) {
                                    return null;
                                  }
                                  handleClick();
                                }}><button>{!loadFlag ? "Loading .." : "Build This Project"} </button></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default CatalogDetail;
