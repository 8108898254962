import React, { useEffect, useState } from 'react';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import { NavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMain } from '../../../hooks/useMain';
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";

const zip = new JSZip();

const ShowUserProject = ({ notify }) => {
  let { getUserBuilders } = useMain();
  const [data, setData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(true);
  const [fileArr, setFileArr] = useState([]);
  var [userBuilder, setUserBuilder] = useState({});
  const [downIndex, setDownIndex] = useState(-1);

  function downloadURI(uri, name = '') {
    var link = document.createElement("a");
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const download = (item) => {
    return axios.get(item.url, { responseType: "blob" }).then((resp) => {
      zip.file(item.name, resp.data);
    });
  };

  const downloadAll = (flag = false) => {
    // console.log(fileArr);
    if (flag) {
      fileArr[downIndex].splice(0, 1);
      fileArr[downIndex].splice(0, 1);
    }

    const arrOfFiles = fileArr[downIndex].map((item) => download(item)); //create array of promises
    Promise.all(arrOfFiles)
      .then(() => {
        //when all promises resolved - save zip file
        zip.generateAsync({ type: "blob" }).then(function (blob) {
          saveAs(blob, "setup.zip");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async () => {
    setLoadFlag(true);
    let ans2 = await getUserBuilders('', JSON.parse(localStorage.getItem("dolibo_user"))._id)  // user builder
    console.log(ans2.data);
    setData(ans2.data);
    setLoadFlag(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getCustomBuilder = async (id, id1, comb, index) => {
    let ans = await getUserBuilders(id, JSON.parse(localStorage.getItem("dolibo_user"))._id, id1, comb)  // user builder
    // console.log(ans);

    if (ans.data && ans.data[0]) {
      setDownIndex(index);
      if (ans.data[0].wireFile && ans.data[0].wireFile.url) {
        setUserBuilder({ [index]: ans.data[0] });

        setFileArr({
          [index]: [{
            name: 'wirediagram.' + ans.data[0]?.wireFile?.url.split('/')[ans.data[0]?.wireFile?.url.split('/').length - 1].split('.')[ans.data[0]?.wireFile?.url.split('/')[ans.data[0]?.wireFile?.url.split('/').length - 1].split('.').length - 1],
            url: ans.data[0]?.wireFile?.url
          }, {
            name: 'enclosure.' + ans.data[0]?.enclosureFile?.url.split('/')[ans.data[0]?.enclosureFile?.url.split('/').length - 1].split('.')[ans.data[0]?.enclosureFile?.url.split('/')[ans.data[0]?.enclosureFile?.url.split('/').length - 1].split('.').length - 1],
            url: ans.data[0]?.enclosureFile?.url
          }, {
            name: 'codefile.' + ans.data[0]?.codeFile?.url.split('/')[ans.data[0]?.codeFile?.url.split('/').length - 1].split('.')[ans.data[0]?.codeFile?.url.split('/')[ans.data[0]?.codeFile?.url.split('/').length - 1].split('.').length - 1],
            url: ans.data[0]?.codeFile?.url
          }]
        });

        document.getElementById('down-btn').click();
      }
      else {
        notify(false, 'No files available to download');
      }
    }
  };

  return (
    <>
      <button
        type="button"
        id="down-btn"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
        style={{ visibility: 'hidden' }}
      >
        Download
      </button>

      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content modal_content1">
            <div className="modal-header modal-header1">
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="42" height="42" rx="21" fill="#F3F6F8" />
                  <path d="M26.8332 16.3415L25.6582 15.1665L20.9998 19.8248L16.3415 15.1665L15.1665 16.3415L19.8248 20.9998L15.1665 25.6582L16.3415 26.8332L20.9998 22.1748L25.6582 26.8332L26.8332 25.6582L22.1748 20.9998L26.8332 16.3415Z" fill="#5E6278" />
                </svg>
              </div>
            </div>

            <div className="modal_download">
              <h2 className='text-center'>Download Your Projects</h2>
            </div>

            <div className="modal-body">
              <div className='modal_files'>
                <div className="modal_file">
                  <span>{userBuilder[downIndex]?.wireFile?.name}</span>
                  <button onClick={() => {
                    downloadURI(userBuilder[downIndex]?.wireFile?.url, 'wirefile');
                  }}>
                    <span>Download File</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                    </svg>
                  </button>
                </div>

                <div className="modal_file">
                  <span>{userBuilder[downIndex]?.enclosureFile?.name}</span>
                  <button onClick={() => {
                    downloadURI(userBuilder[downIndex]?.enclosureFile?.url, 'enclosure');
                  }}>
                    <span>Download File</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                    </svg>
                  </button>
                </div>

                <div className="modal_file">
                  <span>{userBuilder[downIndex]?.codeFile?.name}</span>
                  <button onClick={() => {
                    // downloadURI(userBuilder?.codeFile?.url, 'codefile');
                    downloadAll(true);
                  }}>
                    <span>Download File</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-footer1">
              <button onClick={() => {
                downloadAll(false);
              }} className="download_all">
                <span>Download All File</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                      <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        UserProject
                      </h1>

                      {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.82175 13.7748L10.5883 10L6.82175 6.22525L7.98132 5.06567L12.9157 10L7.98132 14.9344L6.82175 13.7748Z" fill="#7E8299" />
                      </svg> */}

                      {/* <span>User Details</span> */}

                    </div>
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="card mt-5">
                      <div className="card-header border-0 pt-6">
                        {/*begin::Card title*/}
                        <div className="card-title">
                          {/*begin::Search*/}
                          <div className="d-flex align-items-center position-relative my-1">
                            {/* <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                              <span className="path1" />
                              <span className="path2" />
                            </i> */}
                            <h2>Active Projects</h2>
                          </div>
                          {/*end::Search*/}
                        </div>
                        {/*begin::Card title*/}
                        {/*begin::Card toolbar*/}

                        {/*end::Card toolbar*/}
                      </div>
                      <div className="card-body pt-0">
                        <div
                          id="kt_customers_table_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="table-responsive table_resp1">
                            <table
                              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                              id="kt_customers_table"
                            >
                              <thead className='treats'>
                                <tr className="text-start tickna fw-bold fs-7  gs-0">
                                  <th
                                    className="min-w-125px sorting fory"
                                    tabIndex={0}
                                    aria-controls="kt_customers_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Customer Name: activate to sort column ascending"
                                  // style={{ width: "250.521px" }}
                                  >
                                    Title
                                  </th>
                                  <th
                                    className="min-w-125px sorting fory"
                                    tabIndex={0}
                                    aria-controls="kt_customers_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Email: activate to sort column ascending"
                                  // style={{ width: "211.081px" }}
                                  >
                                    Created By
                                  </th>
                                  <th
                                    className="min-w-125px sorting fory"
                                    tabIndex={0}
                                    aria-controls="kt_customers_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Email: activate to sort column ascending"
                                  // style={{ width: "211.081px" }}
                                  >
                                    Creation Date
                                  </th>
                                  <th
                                    className="min-w-125px sorting fory"
                                    tabIndex={0}
                                    aria-controls="kt_customers_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Payment Method: activate to sort column ascending"
                                  // style={{ width: "170.521px" }}
                                  >
                                    Action
                                  </th>
                                  <th
                                    className="min-w-125px sorting fory"
                                    tabIndex={0}
                                    aria-controls="kt_customers_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Company: activate to sort column ascending"
                                  // style={{ width: "187.5px" }}
                                  >
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              
                            {
                              !loadFlag ?  <tbody className="fw-semibold text-gray-600">
                                {data.map((e, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <NavLink
                                          to={`/end-user/build-project/${e.project}/${e.combination}`}
                                          // href="#!"
                                          className="odie mb-1"
                                        >
                                          {e.porjectName}
                                        </NavLink>
                                      </td>
                                      <td>
                                        <a href="#" className="text-gray-600 odd1  mb-1">
                                          {e.user.name}
                                        </a>
                                      </td>
                                      <td>
                                        <a href="#" className="text-gray-600 odd1  mb-1">
                                          {new Date(Number(e.ts)).toLocaleDateString('en-GB')}
                                        </a>
                                      </td>
                                      <td data-filter="mastercard">
                                        <OutsideClickHandler
                                          onOutsideClick={() => {
                                            if (!document.getElementById(`action_boxss${index}`).classList.contains('d-none')) {
                                              document.getElementById(`action_boxss${index}`).classList.add('d-none');
                                            }
                                          }}
                                        >
                                          <div className="dropdown cursor-pointer">
                                            <svg className="dropdown-menu-left"
                                              onClick={() => {
                                                document.getElementById(`action_boxss${index}`).classList.toggle('d-none');
                                              }}
                                              // data-bs-toggle="dropdown"
                                              width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                              <path d="M11 13.6667C10.2666 13.6667 9.66663 14.2667 9.66663 15.0001C9.66663 15.7334 10.2666 16.3334 11 16.3334C11.7333 16.3334 12.3333 15.7334 12.3333 15.0001C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2666 13.6667 17.6666 14.2667 17.6666 15.0001C17.6666 15.7334 18.2666 16.3334 19 16.3334C19.7333 16.3334 20.3333 15.7334 20.3333 15.0001C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2666 13.6667 13.6666 14.2667 13.6666 15.0001C13.6666 15.7334 14.2666 16.3334 15 16.3334C15.7333 16.3334 16.3333 15.7334 16.3333 15.0001C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                            </svg>

                                            <ul id={`action_boxss${index}`} data-popper-placement="left-start" className="d-none action_boxss dropdowns_menu">
                                              <div className='view_details mt-2'>
                                                <div>
                                                  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99992 2.41667C13.1583 2.41667 15.9749 4.19167 17.3499 7C15.9749 9.80833 13.1666 11.5833 9.99992 11.5833C6.83325 11.5833 4.02492 9.80833 2.64992 7C4.02492 4.19167 6.84158 2.41667 9.99992 2.41667ZM9.99992 0.75C5.83325 0.75 2.27492 3.34167 0.833252 7C2.27492 10.6583 5.83325 13.25 9.99992 13.25C14.1666 13.25 17.7249 10.6583 19.1666 7C17.7249 3.34167 14.1666 0.75 9.99992 0.75ZM9.99992 4.91667C11.1499 4.91667 12.0833 5.85 12.0833 7C12.0833 8.15 11.1499 9.08333 9.99992 9.08333C8.84992 9.08333 7.91658 8.15 7.91658 7C7.91658 5.85 8.84992 4.91667 9.99992 4.91667ZM9.99992 3.25C7.93325 3.25 6.24992 4.93333 6.24992 7C6.24992 9.06667 7.93325 10.75 9.99992 10.75C12.0666 10.75 13.7499 9.06667 13.7499 7C13.7499 4.93333 12.0666 3.25 9.99992 3.25Z" fill="#5E6278" />
                                                  </svg>
                                                </div>
                                                <li>
                                                  <NavLink to={`/end-user/build-project/${e.project}/${e.combination}`} className="dropdown-item" >
                                                    View Details
                                                  </NavLink>
                                                </li>
                                              </div>
                                            </ul>
                                          </div>
                                        </OutsideClickHandler>
                                      </td>
                                      <td>
                                        <div onClick={() => {
                                          getCustomBuilder(e.project, e._id, e.combination, index);
                                        }} className="download_ser download_ser1 cursor-pointer">
                                          <span>Download</span>
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody> : <h2>...loading</h2>
                            }
                              
                            </table>
                          </div>

                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  )
}

export default ShowUserProject;