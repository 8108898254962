export const isYoutubeChannelUrl = url => {
    return (
      url.includes("youtube.com/c/") ||
      url.includes("youtube.com/channel/") ||
      url.includes("youtube.com/user/") ||
      url.includes("youtube.com/@") ||
      url.includes("https://youtube.com/@learnelectronics?si=ezABZJ57NTMSeAgK")
    )
  }
  
  