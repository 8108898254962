import React,{useEffect} from 'react';
// import { useNavigate } from 'react-router-dom';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import pickja from '../../../images/pickja.png';
import userSign from '../../../images/signUser.png';
import userCancel from '../../../images/cancelUser.png';
import userCheck1 from '../../../images/userCheck1.png';
import usercancel1 from '../../../images/usercancel1.png';
import uniking from '../../../images/uninking.png';
import { useState } from 'react';
import { useMain } from '../../../hooks/useMain';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import OutsideClickHandler from 'react-outside-click-handler';
import Loader from '../../../Utils/Loader';
const EndUserManagement = ({notify}) => {
    const { getUsers, updateUserStatus, deleteUser, promoteUser, demoteUser } = useMain();
  const navigate = useNavigate();

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [users, setUsers] = useState([]);
  const [updateNum, setUpdateNum] = useState(-1);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [loadFlag, setLoadFlag] = useState(false);
  const [loadFlag1, setLoadFlag1] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(0);

  // ================for Search============
  const [value, setValue] = useState({
    query: ""
  });

   // =============for search=======
   const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
// ===============for search=============
  const handleSearch = (e) => {
    e.preventDefault();
    getData();
  };


  useEffect(() => {
    getData();
  }, [refreshFlag, page, perPage]);

  const getData = async () => {
    setLoadFlag1(true);
    const ans = await getUsers('', value.query, page, perPage);
    setUsers(ans.data);
    setTotal(ans.count);
    setLoadFlag1(false);
  };

  const updateStatus = async (id, status, index) => {
    setUpdateNum(index);
    setLoadFlag(true);
    const ans = await updateUserStatus({ id, status });
    notify(ans.status, ans.message);
    if (ans.status) {
      setRefreshFlag(!refreshFlag);
    }
    // setUpdateNum(-1);
    // setLoadFlag(false);
    setTimeout(() => {
      setUpdateNum(-1);
      setLoadFlag(false);
    }, 400);
  };

  const deleteUser1 = async (id) => {
    // setLoadFlag(true);
    // const ans = await deleteUser(id);
    // notify(ans.status, ans.message);
    // if (ans.status) {
    //   setRefreshFlag(!refreshFlag);
    // }
    // setLoadFlag(false);
    confirmAlert({
      title: 'Are you sure to delete this data?',
      message: 'All related data to this will be deleted',
      buttons: [
        {
          label: 'Yes, Go Ahead!',
          style: {
            background: "#FF5449"
          },
          onClick: async () => {
            const ans = await deleteUser(id);
            console.log(ans);
            if (ans.status) {
              notify(ans.status, ans.message);
              setRefreshFlag(!refreshFlag);
              
            }
            else {
              alert('Something went wrong! ');
            }
          }
        },
        {
          label: 'Cancel',
          onClick: () => null
        }
      ]
    });
  };

  const promoteUser1 = async(id)=>{
    let ans = await promoteUser({id});
    console.log(ans);
    notify(ans.status, ans.message);
    if (ans.status) {
      setRefreshFlag(!refreshFlag);
    }
  };

  const demoteUser1 = async(id)=>{
    let ans = await demoteUser({id});
    console.log(ans);
    notify(ans.status, ans.message);
    if (ans.status) {
      setRefreshFlag(!refreshFlag);
    }
  };

  let user = JSON.parse(localStorage.getItem('dolibo_user'));
  return (
   <>
        {loadFlag1 && <Loader />}
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

          <UserNavbar/>
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <UserSidebar/>
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                      <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Users
                      </h1>

                    </div>
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="section_user">
                      <div className="user_sect1"
                        onMouseEnter={() => setHover1(true)}
                        onMouseLeave={() => setHover1(false)}
                      >
                        <div className="user_img">
                          <img src={hover1 ? uniking : pickja} alt="pickja" />

                        </div>
                        <div className="user_headers">
                          <span>Total Users</span>
                          <h2>{users.length}</h2>
                        </div>
                      </div>
                      <div className="user_sect1"
                        onMouseEnter={() => setHover2(true)}
                        onMouseLeave={() => setHover2(false)}
                      >
                        <div className="user_img">
                          <img src={hover2 ? userCheck1 : userSign} alt="" />
                        </div>
                        <div className="user_headers">
                          <span>Active Users</span>
                          <h2>{users.filter(x => x.status === "true").length}</h2>
                        </div>
                      </div>
                      <div className="user_sect1"
                        onMouseEnter={() => setHover3(true)}
                        onMouseLeave={() => setHover3(false)}
                      >
                        <div className="user_img">
                          <img src={hover3 ? usercancel1 : userCancel} alt="" />
                        </div>
                        <div className="user_headers">
                          <span>Inactive Users</span>
                          <h2>{users.filter(x => x.status === "false").length}</h2>
                        </div>
                      </div>
                    </div>

                    <hr className='hr_line mt-10' />

                    <div className="card card-flush card-body2 card-slush mt-10">
                      <div className="card-header pt-8">
                        <div className="card-title w-100 block card-title1">

                          <div className="active_project">
                            <h2>Active Users</h2>
                            <form onKeyUp={handleSearch} className='search_user'>
                              <input onChange={handleChange} name='query' value={value.query} className='form-control' type="search" placeholder='Search' />
                            </form>
                          </div>

                        </div>
                      </div>

                      <div className="card-body card-body4 card-si table-responsive">
                        <table
                          id="kt_file_manager_list"
                          data-kt-filemanager-table="files"
                          className="table align-middle table-row-dashed fs-6 gy-5"
                        >
                          <thead className="suching">
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 tr-fash">
                              <th className="name44 name58">User</th>
                              <th className="name44 name58">Email</th>
                              {/* <th  className="name44">Phone</th> */}
                              <th className="name44 name58">Status</th>
                              <th className="name44 name58">Action</th>
                            </tr>
                          </thead>

                          <tbody className="fw-semibold text-gray-600">
                            {users.filter(x => x.role !== "ADMIN" && x._id !== user._id ).map((e, index) => {
                              return (
                                <tr key={index} className="tri11">
                                  <td className='cursor-pointer' onClick={() => {
                                    navigate(`/endUser/detail/${e._id}`);
                                  }}>
                                    {e?.name}
                                  </td>
                                  <td >{e?.email}</td>
                                  {/* <td>
                                    {e?.phone}
                                  </td> */}
                                  <td className='cursor-pointer'>
                                    {/* <div onClick={() => {
                                    updateStatus(e._id, e.status === "true" ? "false" : "true", index);
                                  }} className="status-baring">
                                      {loadFlag && index === updateNum ? <p style={{ fontSize: "13px" }}>Updating ..</p> : <>
                                        <div className={e.status === "false" ? "reding" : "green"} />
                                        <p>{e.status === "false" ? "inactive" : "active"}</p>
                                      </>}

                                      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.06 0.529999L4 3.58333L0.94 0.529999L0 1.47L4 5.47L8 1.47L7.06 0.529999Z" fill="#7E8299" />
                                      </svg>
                                    </div> */}
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                        if (!document.getElementById(`action_boxsss${index}`).classList.contains('d-none')) {
                                          document.getElementById(`action_boxsss${index}`).classList.add('d-none');
                                        }
                                      }}
                                    >
                                    <div className="dropdown">
                                      <div onClick={() => {
                                        // updateStatus(e._id, e.status === "true" ? "false" : "true", index);
                                        document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                      }} className="status-baring">
                                        {loadFlag && index === updateNum ? <p style={{ fontSize: "13px" }}>Updating ..</p> : <>
                                          <div className={e.status === "false" ? "reding" : "green"} />
                                          <p>{e.status === "false" ? "inactive" : "active"}</p>
                                        </>}

                                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.06 0.529999L4 3.58333L0.94 0.529999L0 1.47L4 5.47L8 1.47L7.06 0.529999Z" fill="#7E8299" />
                                        </svg>
                                      </div>

                                      <ul id={`action_boxsss${index}`} data-popper-placement="left-start" className=" d-none action_boxsss  dropdowns_menu dt_menu">
                                        <div className='ting' onClick={() => {
                                          updateStatus(e._id, "true", index);
                                          document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                        }}>
                                         <div className="act"></div>
                                         <span>active</span> 
                                        </div>
                                        <div className='mt-2 ting' onClick={() => {
                                          updateStatus(e._id, "false", index);
                                          document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                        }}>
                                        <div className='inact'></div>
                                         <span>Inactive</span> 
                                        </div>
                                      </ul>
                                    </div>
                                    </OutsideClickHandler>
                                  </td>

                                  <td className='cursor-pointer'>
                                    <OutsideClickHandler
                                      onOutsideClick={() => {
                                        if (!document.getElementById(`action_boxs${index}`).classList.contains('d-none')) {
                                          document.getElementById(`action_boxs${index}`).classList.add('d-none');
                                        }
                                      }}
                                    >
                                      <div className="dropdown">
                                        <svg className=" dropdown-menu-left"
                                          onClick={() => {
                                            document.getElementById(`action_boxs${index}`).classList.toggle('d-none');
                                          }}
                                          // data-bs-toggle="dropdown"
                                          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                          <path d="M11 13.6667C10.2666 13.6667 9.66663 14.2667 9.66663 15.0001C9.66663 15.7334 10.2666 16.3334 11 16.3334C11.7333 16.3334 12.3333 15.7334 12.3333 15.0001C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2666 13.6667 17.6666 14.2667 17.6666 15.0001C17.6666 15.7334 18.2666 16.3334 19 16.3334C19.7333 16.3334 20.3333 15.7334 20.3333 15.0001C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2666 13.6667 13.6666 14.2667 13.6666 15.0001C13.6666 15.7334 14.2666 16.3334 15 16.3334C15.7333 16.3334 16.3333 15.7334 16.3333 15.0001C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                        </svg>

                                        <ul id={`action_boxs${index}`} data-popper-placement="left-start" className=" d-none action_boxs  dropdowns_menu dt_menu">
                                          <div className='view_details view_details1 mt-2'>
                                            <div>
                                              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.99992 2.41667C13.1583 2.41667 15.9749 4.19167 17.3499 7C15.9749 9.80833 13.1666 11.5833 9.99992 11.5833C6.83325 11.5833 4.02492 9.80833 2.64992 7C4.02492 4.19167 6.84158 2.41667 9.99992 2.41667ZM9.99992 0.75C5.83325 0.75 2.27492 3.34167 0.833252 7C2.27492 10.6583 5.83325 13.25 9.99992 13.25C14.1666 13.25 17.7249 10.6583 19.1666 7C17.7249 3.34167 14.1666 0.75 9.99992 0.75ZM9.99992 4.91667C11.1499 4.91667 12.0833 5.85 12.0833 7C12.0833 8.15 11.1499 9.08333 9.99992 9.08333C8.84992 9.08333 7.91658 8.15 7.91658 7C7.91658 5.85 8.84992 4.91667 9.99992 4.91667ZM9.99992 3.25C7.93325 3.25 6.24992 4.93333 6.24992 7C6.24992 9.06667 7.93325 10.75 9.99992 10.75C12.0666 10.75 13.7499 9.06667 13.7499 7C13.7499 4.93333 12.0666 3.25 9.99992 3.25Z" fill="#5E6278" />
                                              </svg>

                                            </div>
                                            <span onClick={() => {
                                              navigate(`/endUser/detail/${e._id}`);
                                            }}>
                                              <a className="dropdown-item" >
                                                View Details
                                              </a>
                                            </span>
                                          </div>
                                          <div onClick={() => {
                                            deleteUser1(e._id);
                                          }} className='view_details view_details1'>
                                            <div>
                                              <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.33341 5.5V13.8333H2.66675V5.5H9.33341ZM8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333H8.91675L8.08341 0.5ZM11.0001 3.83333H1.00008V13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333Z" fill="#5E6278" />
                                              </svg>
                                            </div>
                                            <span>
                                              <a className="dropdown-item" >
                                                Delete
                                              </a>
                                            </span>
                                          </div>
                                          {/* <div onClick={() => {
                                            if (e.role === "SUB_ADMIN") {
                                              demoteUser1(e._id);
                                            }
                                            else {
                                              promoteUser1(e._id);
                                            }
                                          }} className='view_details view_details1'>
                                            <div>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-square" viewBox="0 0 16 16">
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z" />
                                              </svg>
                                            </div>
                                            <span>
                                              <a className="dropdown-item" >
                                                {e.role === "SUB_ADMIN" ? "Demote to User" : "Promote to Admin"}
                                              </a>
                                            </span>
                                          </div> */}
                                        </ul>
                                      </div>
                                    </OutsideClickHandler>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <div style={{width: 'fit-content', margin: 'auto'}} className="view_all">
                          <button disabled={page===1} className='btn btn-primary btn1' onClick={()=>{
                            if(page>1)
                            {
                              setPage(page-1);
                            }
                          }}>Previous</button>
                          <span className='btn2'>Page {page}</span>
                          <button disabled={(page*perPage)>=total} className='btn btn-primary btn3' onClick={()=>{
                            if((page*perPage)<total)
                            {
                              setPage(page+1);
                            }
                          }}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
   </>
  )
}

export default EndUserManagement