import React from 'react';
import { Comment } from 'react-loader-spinner'

const Loader = () => {
    return (
        <>
            <div className="laoder">
                <Comment
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#181c32"
                />
            </div>
        </>
    );
};

export default Loader;
