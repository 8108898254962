import React, { useState, useEffect } from 'react'
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import cant1 from '../../../images/cant1.png';
import cant2 from '../../../images/cant2.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMain } from '../../../hooks/useMain';
import star from '../../../images/star.svg';
import start1 from '../../../images/star1.svg'

const ProjectCatalog = ({notify}) => {
  const [data, setData] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  let { getProjects, updateProject, addProjectToFav } = useMain();

  var user = localStorage.getItem('dolibo_user');
  if(user)
  {
    user=JSON.parse(user);
  }

  useEffect(() => {
    getData();
  }, [refreshFlag]);

  const navigate = useNavigate();

  const getData = async () => {
    const ans = await getProjects('', '');
    console.log(ans.data);
    setData(ans.data);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                      <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Project Catalog
                      </h1>
                    </div>
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="counter_user">
                      <div className="counter_card">
                        {data.map((e, index) => {
                          return (
                            <div key={index} className="counter_box">
                              {user.favProjects.includes(e._id) ? <div style={{ cursor: "pointer" }} onClick={async () => {
                                // const ans = await updateProject({ id: e._id, isFavorite: "false" });
                                const ans = await addProjectToFav({ projectId: e._id, type: "remove" });
                                // console.log(ans);
                                localStorage.setItem('dolibo_user', JSON.stringify({...user, favProjects: user.favProjects.filter(x=>x!==e._id)}));

                                // alert("Removed from Favourite");
                                notify("success","Removed from Favourite")
                                setRefreshFlag(!refreshFlag);
                              }} className='d_count'>
                                <svg style={{ color: "gold" }} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                              </div> : <div style={{ cursor: "pointer" }} onClick={async () => {
                                // const ans = await updateProject({ id: e._id, isFavorite: "true" });
                                const ans = await addProjectToFav({ projectId: e._id, type: "add" });
                                // console.log(ans);
                                localStorage.setItem('dolibo_user', JSON.stringify({...user, favProjects: user.favProjects.concat(e._id)}));

                                // alert("Added to Favourite");
                                notify("success","Added to Favourite")
                                setRefreshFlag(!refreshFlag);

                              }} className='d_count'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                </svg>
                              </div>}
                              <div className="cant1">
                                <img style={{ objectFit: "cover" }} width="189" height="106" src={e.img[0]?.url ? e.img[0]?.url : cant1} alt="cant1" />
                              </div>
                              <div className="cant2 mt-2">
                                <h3>{e.title}</h3>
                                <p>{e.desc.simpleText}</p>
                              </div>
                              <div className="cant3">
                                <button onClick={() => {
                                  navigate(`/endUser/catalogDetail/${e._id}`);
                                }}>View Detail</button>
                              </div>

                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  )
}

export default ProjectCatalog