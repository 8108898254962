import "./App.css";
import "./style.bundle.css";
// import "../src/fontsom.css";
// import "./font-awesome.min.css";
import "./custom.css";
import $ from "jquery";
// import "./datatables.bundle.css";
// import "./plugins.bundle.css";
import "./style.css";
// import "./fullcalendar.bundle.css";
// import "./summernote-bs4.min.css";
// import "./tempus-dominus.css";
// import "./tempus-dominus.css.map";
// import "./tempus-dominus.min.css";
// import "./vis-timeline.bundle.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import MainState from "./context/MainState";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import Dashboard2 from "./components/EndUser/Dashboard/Dashboard";
// import Dashboard1 from "./components/Admin/Dashboard/Dashboard1";
// import Registeration from "./components/EndUser/Registeration/Registeration";
import { useEffect, useState } from "react";
import ShowProject from "./components/Admin/Project/ShowProject";
import CreateProject from "./components/Admin/Project/CreateProject";
import 'react-notifications/lib/notifications.css';
import UserShow from "./components/Admin/userManagement/UserShow";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Util from "./Utils/Util";
import Component from "./components/Admin/Component/Component";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import UserDetail from "./components/Admin/userManagement/UserDetail";
import UserProject from "./components/Admin/userManagement/UserProject";
import Signin from "./components/Auth/Signin";
import Signup from "./components/Auth/Signup";
import ForgotPass from "./components/Auth/ForgotPass";
import GetOtp from "./components/Auth/GetOtp";
import ResetPassword from "./components/Auth/ResetPassword";
import UserInformative from "./components/EndUser/UserProfile/UserInformative";
import MyProfile from "./components/EndUser/Profile/MyProfile";
import BuildProject from "./components/EndUser/BuildProject/BuildProject";
import UserComponents from "./components/EndUser/EndComponent/UserComponents";
import EditProfile from "./components/EndUser/Profile/EditProfile";
import ProjectCatalog from "./components/EndUser/projectCatalog/ProjectCatalog";
import ShowProject1 from "./components/EndUser/BuildProject/ShowProject";
import EndUserManagement from "./components/EndUser/EndUserManagement/EndUserManagement";
import EndUserDetails from "./components/EndUser/EndUserManagement/EndUserDetails";
import EndUserProject from "./components/EndUser/EndUserManagement/EndUserProject";
import CreateProjectUser from "./components/EndUser/BuildProject/CreateProjectUser";
import Test1 from "./components/Test1";
import CatalogDetail from "./components/EndUser/projectCatalog/CatalogDetail";
import ShowUserProject from "./components/EndUser/BuildProject/ShowUserProject";
import EndUserEditable from "./components/EndUser/EndUserManagement/EndUserEditable";
import BuildProject1 from "./components/EndUser/BuildProject/BuildProject1";

// import { on } from "../public/assets/js/scripts.bundle";
// import "./static/js/scripts.bundle.js";
// import '.././public/assets/js/scripts.bundle';

function App() {
  const notify = (status, message) => {
    if (status) {
      NotificationManager.success(message, 'Success! ');
    }
    else {
      NotificationManager.error(message, 'Failed! ');
    }
  };

  return (
    <>
      <MainState>
        <BrowserRouter>
          <NotificationContainer />
          <Util />
          <Routes>
            <Route path="/" element={<Home />} />
            
            <Route element={<PrivateRoute />}>
              <Route path="/admin/dashboard" element={<Dashboard notify={notify} />} />
              <Route path="/admin/project" element={<ShowProject notify={notify} />} />
              <Route path="/admin/createProject" element={<CreateProject notify={notify} />} />
              <Route path="/admin/updateProject/:id" element={<CreateProject isEdit={true} notify={notify} />} />
              <Route path="/admin/userShow" element={<UserShow notify={notify} />} />
              <Route path="/admin/components" element={<Component notify={notify} />} />
              <Route path="/admin/userDetails/:id" element={<UserDetail notify={notify} />} />
              <Route path="/admin/userProjects/:id/:id1/:comb" element={<UserProject notify={notify} />} />
              {/* <Route path="/admin/dashboard1" element={<Dashboard1 />} /> */}
              {/* <Route path="/dash" element={<Dashboard1 />} /> */}
              {/* <Route path="/endUser/register" element={<Registeration notify={notify} />} /> */}
              
              <Route path="/endUser/dashboard" element={<Dashboard2 notify={notify} />} />
              <Route path="/endUser/userIn" element={<UserInformative notify={notify} />} />
              <Route path="/endUser/myProfile" element={<MyProfile notify={notify} />} />
              <Route path="/end-user/build-project/:id" element={<BuildProject notify={notify}/>}/>
              <Route path="/end-user/build-project/:id/:comb" element={<BuildProject1 notify={notify}/>}/>
              <Route path="/endUser/component" element={<UserComponents notify={notify}/>}/>
              <Route path="/endUser/editProfile" element={<EditProfile notify={notify}/>}/>
              <Route path="/endUser/projectCatalog" element={<ProjectCatalog notify={notify}/>}/>
              <Route path="/endUser/catalogDetail/:id" element={<CatalogDetail notify={notify}/>}/>
              <Route path="/endUser/showProject" element={<ShowProject1 notify={notify}/>}/>
              <Route path="/endUser/userShow" element={<EndUserManagement notify={notify}/>}/>
              <Route path="/endUser/detail/:id" element={<EndUserDetails notify={notify}/>}/>
              <Route path="/endUser/project" element={<EndUserProject notify={notify}/>}/>
              <Route path="/endUser/createUserProject" element={<CreateProjectUser notify={notify}/>}/>
              <Route path="/endUser/updateProject/:id" element={<CreateProjectUser isEdit={true} notify={notify}/>}/>
              <Route path="/endUser/onProject" element={<ShowUserProject notify={notify}/>}/>
              <Route path="/endUser/editable/:id/:id1/:comb" element={<EndUserEditable notify={notify}/>}/>
            </Route>

            {/* <Route path="/login" element={<Auth notify={notify} />} />
            <Route path="/user/login" element={<Auth notify={notify} />} /> */}

            {/* ======================new pages of figma=============== */}
            <Route path="/signin" element={<Signin notify={notify} />} />
            <Route path="/signup" element={<Signup notify={notify} />} />
            <Route path="forgotPassword" element={<ForgotPass notify={notify} />} />
            <Route path="reset-password" element={<ResetPassword notify={notify} />} />
            <Route path="/getOtp" element={<GetOtp notify={notify} />} />

            <Route path="/test" element={<Test1 />} />

          </Routes>
        </BrowserRouter>
      </MainState>
    </>
  )
}

export default App;
