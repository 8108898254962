import React, { useEffect, useState } from 'react'
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import rtyu from '../../../images/rtyu.png';
import { useMain } from '../../../hooks/useMain';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import userst from '../../../images/user.png';
import poll from '../../../images/poll.png';

const MyProfile = () => {
  var user = JSON.parse(localStorage.getItem('dolibo_user'));

  const { getUsers, getUserBuilders } = useMain();
  const { id } = useParams();
  const navigate=useNavigate();

  const [data, setData] = useState({});
  const [userImage, setUserImage] = useState({});
  const [loadFlag, setLoadFlag] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setLoadFlag(true);
    // let ans = await getUsers(id);
    // console.log(ans.data);
    // setData(ans.data[0]);

    let ans2 = await getUserBuilders('', JSON.parse(localStorage.getItem("dolibo_user"))._id);
    console.log(ans2.data);
    setProjects(ans2.data);

    if (user.img && user.img.url) {
      // console.log(user.img);
      setUserImage(user.img);
    }
    setLoadFlag(false);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar user={user} />

          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="user_profil user_profiling">
                      <div className="header_profile">
                        <h2>My Profile</h2>
                      </div>
                      <div className="rafiql d-flex align-items-center">
                        <div className="rafiq_img">
                          {/* <div>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                          </div> */}
                          <img className='rafg' src={userImage.url ? userImage.url : userst} alt="rtyu" />
                        </div>
                        <div className="rafiq_header">
                          <h2>{user?.name}</h2>

                          <NavLink to="/endUser/editProfile"><button>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18.3103 4.87866L14.1216 0.688973C13.9823 0.549649 13.8169 0.439129 13.6349 0.363725C13.4529 0.288322 13.2578 0.249512 13.0608 0.249512C12.8638 0.249512 12.6687 0.288322 12.4867 0.363725C12.3047 0.439129 12.1393 0.549649 12 0.688973L0.439695 12.2502C0.299801 12.389 0.188889 12.5542 0.113407 12.7362C0.0379245 12.9183 -0.000621974 13.1135 7.58902e-06 13.3105V17.5002C7.58902e-06 17.898 0.158043 18.2796 0.439347 18.5609C0.720652 18.8422 1.10218 19.0002 1.50001 19.0002H5.6897C5.88675 19.0009 6.08197 18.9623 6.26399 18.8868C6.44602 18.8113 6.61122 18.7004 6.75001 18.5605L18.3103 7.00022C18.4496 6.86093 18.5602 6.69556 18.6356 6.51355C18.711 6.33153 18.7498 6.13645 18.7498 5.93944C18.7498 5.74243 18.711 5.54735 18.6356 5.36534C18.5602 5.18333 18.4496 5.01795 18.3103 4.87866ZM1.81032 13.0002L9.75001 5.06053L11.3147 6.62522L3.37501 14.564L1.81032 13.0002ZM1.50001 14.8105L4.1897 17.5002H1.50001V14.8105ZM6.00001 17.1899L4.43532 15.6252L12.375 7.68553L13.9397 9.25022L6.00001 17.1899ZM15 8.18991L10.8103 4.00022L13.0603 1.75022L17.25 5.93897L15 8.18991Z" fill="#475569" />
                            </svg>
                            <span>Edit</span>
                          </button></NavLink>
                        </div>
                      </div>
                      <div className="person_information">
                        <div className='peru'>
                          <h2>Personal Information</h2>

                        </div>
                        <div className="col-md-12 mt-8">
                          <div className="row mt-4">

                            <div className="col-md-3">
                              <div className="first_name">
                                <span>Email Address</span>
                                <p>{user?.email}</p>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-5 ">
                              <div className="bio">
                                <h3>BIO</h3>
                                <p>{user?.bio}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="address">
                        <div className='peru'>
                          <h2>Address</h2>
                        </div>
                        <div className="col-md-12 mt-8">
                          <div className="row">

                            <div className="col-md-3">
                              <div className="first_name">
                                <span>Country</span>
                                <p>{user?.country}</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="last_name">
                                <span>City/State</span>
                                <p>{user?.state}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <>
                      <div className='ty_my'>
                        <h2>My Projects</h2>
                        {projects.filter(x=>(user.favProjects.includes(x.project))).map((e, index) => {
                          return (
                            <div key={index} className="row yt_pring mt-10">
                              <div className="col-md-3">
                                <div className="count_img">
                                  <img className='t_img' src={e.projectImg} alt="poll" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="detr">
                                  <h3 style={{cursor: 'pointer'}} onClick={()=>{
                                    navigate(`/end-user/build-project/${e.project}/${e.combination}`);
                                  }}>{e?.porjectName}</h3>
                                  <p>{e?.projectDesc?.simpleText}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="modified">
                                  <span>Modified: {new Date().toLocaleDateString('en-GB')}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  </div>

                  <div id="kt_app_footer" className="app-footer">
                    <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                      <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-semibold me-1">2023©</span>
                        <a
                          href="#"
                          target="_blank"
                          className="text-gray-800 text-hover-primary"
                        >
                          Dolibo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <i className="ki-duotone ki-arrow-up">
            <span className="path1" />
            <span className="path2" />
          </i>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
