import React, { useEffect, useState } from 'react'
import { useMain } from '../../../hooks/useMain';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import Loader from '../../../Utils/Loader';

const UserComponents = ({ notify }) => {
  let { getProjects, getComponents, postComponent, updateComponent, deleteComponent, getSubComponents, postSubComponent, updateSubComponent, deleteSubComponent } = useMain();

  const [data, setData] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [refreshFlag1, setRefreshFlag1] = useState(false);
  const [loadFlag, setLoadFlag] = useState(true);
  const [project, setProject] = useState('');
  const [isChild, setIsChild] = useState(false);
  const [componentValue, setComponentValue] = useState({
    id: '',
    text: ''
  });
  const [components, setComponents] = useState([]);
  const [subComponent, setSubComponent] = useState({});
  const [isComponentEdit, setIsComponentEdit] = useState(false);
  const [componentId, setComponentId] = useState('');

  useEffect(() => {
    getComponents1();
  }, [refreshFlag]);

  const getComponents1 = async () => {
    setLoadFlag(true);
    let ans = await getComponents();
    // console.log(ans);
    setComponents(ans.data);
    setLoadFlag(false);
  };

  const handleChange = (e) => {
    setProject(e.target.value);
    setRefreshFlag(!refreshFlag);
  };

  const handleChange1 = (e) => {
    setComponentValue({ ...componentValue, [e.target.name]: e.target.value });
  };

  const handleTab = async (tab) => {
    if (tab === 2) {
      let ans = await getComponents(project);
      console.log(ans);
      setComponents(ans.data);
    }
  };

  const handleComponents = async (e) => {
    e.preventDefault();
    console.log(componentValue);
    let ans;
    if (isChild) {
      if (isComponentEdit) {
        ans = await updateSubComponent({ id: componentValue.id, title: componentValue.text });
      }
      else {
        ans = await postSubComponent({ project, title: componentValue.text, component: componentId });
      }
    }
    else {
      if (isComponentEdit) {
        ans = await updateComponent({ id: componentValue.id, title: componentValue.text });
      }
      else {
        ans = await postComponent({ project, title: componentValue.text });
      }
    }

    console.log(ans.data);
    if (ans.status) {
      document.getElementById('kt_drawer_example_basic_close').click();
      setComponentValue({
        text: "",
        id: ''
      });

      if (isChild) {
        handleOpenChild(ans.data.component, true);
      }
      if (!isChild) {
        handleTab(2);
      }
    }
    // setComponents([]);
  };

  const handleEditComponent = (data) => {
    setComponentValue({
      text: data.title,
      id: data._id
    });
    setIsComponentEdit(true);
  };

  const handleDeleteComponent = async (id) => {
    const ans = await deleteComponent(id);
    console.log(ans);
    if (ans.status) {
      handleTab(2);
    }
  };

  const handleDeleteSubComponent = async (id, id1) => {
    const ans = await deleteSubComponent(id);
    console.log(ans);
    notify(ans.status, ans.message);
    if (ans.status) {
      handleOpenChild(id1,true);
    }
  }

  const handleOpenChild = async (id, flag=false) => {
    if(flag){
      let ans = await getSubComponents(id);
      if (ans.data.length === 0) {
        notify(true, 'No sub-components found!');
      }
      setSubComponent({ ...subComponent, [id]: ans.data });
    }
    else
    {
      if (document.getElementById(`scp-${id}`).classList.contains('first-flag')) {
        document.getElementById(`scp-${id}`).classList.toggle('com-d-flag');
      }
      else {
        document.getElementById(`scp-${id}`).classList.add('first-flag');
        // setLoadFlag(true);
        let ans = await getSubComponents(id);
        if (ans.data.length === 0) {
          notify(true, 'No sub-components found!');
        }
        setSubComponent({ ...subComponent, [id]: ans.data });
        // setLoadFlag(false);
      }
    }
  };

  return (
    <>
      {loadFlag && <Loader />}

      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar/>

          <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper" >
            <UserSidebar/>

            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title d-flex align-items-center justify-content-center flex-wrap me-3">
                      <h1 className="page-heading page-heading1 d-flex   flex-column justify-content-center my-0">
                        Component Management
                      </h1>
                      {/* <svg className='my-0 tyu' width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.821751 8.77478L4.58831 5L0.821751 1.22522L1.98132 0.0656433L6.91568 5L1.98132 9.93436L0.821751 8.77478Z" fill="#7E8299" />
                      </svg>

                      <p className='my-0 me-3 tyu1'>YouTube Counter and Clock</p> */}

                    </div>
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="um-content flex-lg-row-fluid um-content222">
                      <div>
                        <div className="parents_component parents_compi">
                          {(refreshFlag1 || !refreshFlag1) && components.map((e, index) => {
                            return (
                              <>
                                <div key={index} onClick={() => {
                                  handleOpenChild(e._id);
                                }} className="parent_component pc cursor-pointer">
                                  <div className="pc1">
                                    <p>{e.title}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-chevron-down m-lg-3" viewBox="0 0 16 16">
                                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                  </div>

                                  <div className="pc2">
                                    <svg onClick={() => {
                                      setIsChild(false);
                                      handleEditComponent(e);
                                    }} id='kt_drawer_example_basic_button' className='cursor-pointer' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="0.57692" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" fill="white" />
                                      <path d="M17.2565 14.1209L17.9828 14.8472L10.8302 21.9998H10.1038V21.2735L17.2565 14.1209ZM20.0986 9.36826C19.9012 9.36826 19.6959 9.4472 19.5459 9.5972L18.1012 11.0419L21.0617 14.0025L22.5064 12.5577C22.8143 12.2498 22.8143 11.7525 22.5064 11.4446L20.6591 9.5972C20.5012 9.43931 20.3038 9.36826 20.0986 9.36826ZM17.2565 11.8867L8.5249 20.6182V23.5787H11.4854L20.217 14.8472L17.2565 11.8867Z" fill="#5E6278" />
                                      <rect x="0.57692" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" stroke="#BFC8D1" strokeWidth="1.15384" />
                                    </svg>

                                    <svg onClick={() => {
                                      setIsChild(false);
                                      handleDeleteComponent(e._id);
                                    }} className='cursor-pointer' width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="0.734634" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" fill="white" />
                                      <path d="M11.8945 21.9998C11.8945 22.8682 12.605 23.5787 13.4734 23.5787H19.7892C20.6576 23.5787 21.3681 22.8682 21.3681 21.9998V12.5261H11.8945V21.9998ZM13.4734 14.1051H19.7892V21.9998H13.4734V14.1051ZM19.3944 10.1577L18.605 9.36826H14.6576L13.8681 10.1577H11.105V11.7367H22.1576V10.1577H19.3944Z" fill="#5E6278" />
                                      <rect x="0.734634" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" stroke="#BFC8D1" strokeWidth="1.15384" />
                                    </svg>

                                    <svg id='kt_drawer_example_basic_button' className='cursor-pointer' onClick={() => {
                                      setIsChild(true);
                                      setIsComponentEdit(false);
                                      setComponentId(e._id);
                                    }} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="0.892838" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" fill="white" />
                                      <path d="M22.1576 17.263H17.4208V21.9998H15.8418V17.263H11.105V15.684H15.8418V10.9472H17.4208V15.684H22.1576V17.263Z" fill="#5E6278" />
                                      <rect x="0.892838" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" stroke="#BFC8D1" strokeWidth="1.15384" />
                                    </svg>
                                  </div>
                                </div>

                                <div id={`scp-${e._id}`} style={{ display: 'flex', alignItems: 'flex-end', width: '100%', flexDirection: 'column' }}>
                                  {subComponent[e._id] && subComponent[e._id]?.length > 0 && subComponent[e._id].map((f, index1) => {
                                    return (
                                      <div key={index1} className="child_component pc">
                                        <div className="pc1">
                                          <p className='subComponent'>{f.title}</p>
                                        </div>

                                        <div className="pc2">
                                          <svg id='kt_drawer_example_basic_button' onClick={() => {
                                            setIsChild(true);
                                            handleEditComponent(f);
                                          }} className='cursor-pointer' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.57692" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" fill="white" />
                                            <path d="M17.2565 14.1209L17.9828 14.8472L10.8302 21.9998H10.1038V21.2735L17.2565 14.1209ZM20.0986 9.36826C19.9012 9.36826 19.6959 9.4472 19.5459 9.5972L18.1012 11.0419L21.0617 14.0025L22.5064 12.5577C22.8143 12.2498 22.8143 11.7525 22.5064 11.4446L20.6591 9.5972C20.5012 9.43931 20.3038 9.36826 20.0986 9.36826ZM17.2565 11.8867L8.5249 20.6182V23.5787H11.4854L20.217 14.8472L17.2565 11.8867Z" fill="#5E6278" />
                                            <rect x="0.57692" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" stroke="#BFC8D1" strokeWidth="1.15384" />
                                          </svg>

                                          <svg onClick={() => {
                                            setIsChild(true);
                                            handleDeleteSubComponent(f._id, e._id);
                                          }} className='cursor-pointer' width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.734634" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" fill="white" />
                                            <path d="M11.8945 21.9998C11.8945 22.8682 12.605 23.5787 13.4734 23.5787H19.7892C20.6576 23.5787 21.3681 22.8682 21.3681 21.9998V12.5261H11.8945V21.9998ZM13.4734 14.1051H19.7892V21.9998H13.4734V14.1051ZM19.3944 10.1577L18.605 9.36826H14.6576L13.8681 10.1577H11.105V11.7367H22.1576V10.1577H19.3944Z" fill="#5E6278" />
                                            <rect x="0.734634" y="0.57692" width="30.8462" height="30.8462" rx="1.42308" stroke="#BFC8D1" strokeWidth="1.15384" />
                                          </svg>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div className='add-component'>
                          <button id='kt_drawer_example_basic_button' onClick={() => {
                            setIsComponentEdit(false);
                            setIsChild(false);
                          }}>+ ADD COMPONENTS</button>
                        </div>
                      </div>
                    </div>

                    <div
                      id="kt_drawer_example_basic"

                      className="bg-white t_active"
                      data-kt-drawer="true"
                      data-kt-drawer-activate="true"
                      data-kt-drawer-toggle="#kt_drawer_example_basic_button"
                      data-kt-drawer-close="#kt_drawer_example_basic_close"
                      // data-kt-drawer-width="480px"
                    >
                      <form
                        id="form-render-items1"
                        className="card w-100 border-0 rounded-0"
                        onSubmit={handleComponents}
                      >
                        {/* head */}
                        <div className="card-header card-header11 pe-5 rounded-0">
                          <div className="d-flex justify-content-center flex-column me-3">
                            <h2>Links</h2>
                          </div>
                        </div>
                        {/* popup body */}
                        <div className="card-body card-body11">
                          <p className="text-gray-500 mt-0 mb-5 fs-6">
                            Your social links may appear in more than one place on your website. Edit
                            a link here and. it will be updated everywhere.
                          </p>
                          <div className="mb-5 w-100">
                            <label className="form-label fs-5">Text</label>
                            <input
                              type="text"
                              name="text"
                              onChange={handleChange1}
                              value={componentValue.text}
                              className="form-control link-control"
                              placeholder="Processor Type #1"
                              required
                            />
                          </div>
                        </div>
                        <div className="card-footer p-5 d-flex justify-content-between align-items-center">
                          <button
                            className="btn btn-outline w-100px"
                            id="kt_drawer_example_basic_close"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary w-100px"
                            id="kt_drawer_example_basic"
                            type="submit"
                          >
                            SAVE
                          </button>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default UserComponents;
