import React, { useEffect, useState } from 'react';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import rtyu from '../../../images/rtyu.png'
import { ReactFormGenerator } from 'react-form-builder2';
import { useMain } from '../../../hooks/useMain';
import { useParams } from 'react-router-dom';
import Loader from '../../../Utils/Loader';

const cartesian = (args) => {
  var r = [], max = args.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max)
        r.push(a);
      else
        helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
};

const EndUserEditable = ({ notify }) => {
  let { getBuilders, getUserBuilders, getFiles, getSubComponents, getComponents, postUserBuilder } = useMain();

  const { id, id1, comb } = useParams();
  const [data, setData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(true);
  const [loadFlag1, setLoadFlag1] = useState(false);
  const [userBuilderId, setUserBuilderId] = useState('');
  const [adminBuilderId, setAdminBuilderId] = useState('');
  const [title, setTitle] = useState('');
  const [files2, setFiles2] = useState('');
  const [projectName, setProjectName] = useState('');

  const [components, setComponents] = useState([]);
  const [subComponent, setSubComponent] = useState({});
  const [files3, setFiles3] = useState({});
  const [toggle, setToggle] = useState({});

  let sc1 = {};

  const handleCheckBox = (name, index) => {
    setFiles2(() => {
      return { ...files2, [index]: [name] };
    });
  };

  const handleCheckBox1 = (name, index) => {
    setFiles3(() => {
      return { ...files3, [index]: [name] };
    });
  };

  const handleOpenChild = async (id) => {
    if (document.getElementById(`scp-${id}`).classList.contains('first-flag')) {
      document.getElementById(`scp-${id}`).classList.toggle('com-d-flag');
    }
    else {
      document.getElementById(`scp-${id}`).classList.add('first-flag');
      // setLoadFlag(true);
      let ans = await getSubComponents(id);
      if (ans.data.length === 0) {
        notify(true, 'No sub-components found!');
      }
      setSubComponent({ ...subComponent, [id]: ans.data });
      // setLoadFlag(false);
    }
  };

  const getData = async () => {
    setLoadFlag(true);
    let ans3 = await getFiles(id1);
    let ans2 = await getBuilders(id1);  // admin builder
    let ans = await getUserBuilders('', '', id, comb)  // user builder

    setToggle(ans3.data[0].toggle);
    let ans4 = await getComponents(id);  // Components
    setComponents(ans4.data);

    setData(ans2?.data[0]?.data);
    setAdminBuilderId(ans2.data[0]._id);

    if (ans.data && ans.data[0]) {
      setFiles3(ans.data[0].files3);
      setFiles2(ans.data[0].files2);
      setTitle(ans?.data[0]?.porjectName);
      setProjectName(ans.data[0].projectName1);
      setUserBuilderId(ans.data[0]._id);
      setAnswerData(ans.data[0].data);
    }

    setLoadFlag(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleSubmit = async (e) => {
    setLoadFlag1(true);
    let p = document.querySelectorAll('.parent_component').length;
    if (files2 === "" || Object.keys(files2).filter(x => files2[x].length > 0).length !== p) {
      notify(false, 'Please select a valid combination from each component.');
      return;
    }

    for (let i of Object.values(files2)) {
      if (i.length > 1) {
        notify(false, 'Please select a valid combination. You can only select 1 Sub Component from each Component');
        return;
      }
    }

    let tp = [];
    for (let i of cartesian(Object.values(files2))) {
      tp.push(String(i.map(x => ' ' + x)));
    }
    let combination = tp[0];

    let t = [];
    for (let i of e) {
      if (i.name.includes('text_input')) {
        let key = document.getElementsByName(i.name)[0].previousElementSibling.children[0].innerText;
        let value = document.getElementsByName(i.name)[0].value;
        let type = 'single';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('checkbox')) {
        let key = '';
        let t1 = [];

        if (i.value.length > 0) {
          key = document.getElementsByName(`option_${i.value[0]}`)[0].parentNode.parentNode.children[0].children[0].innerText;

          for (let j of i.value) {
            let val = document.getElementsByName(`option_${j}`)[0].value;
            t1.push(val);
          }
        }

        let value = t1;
        let type = 'multiple';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('radiobutton')) {
        let key = document.getElementsByName(i.name)[0].parentNode.previousElementSibling.children[0].innerText;
        let t1 = document.getElementsByName(i.name);
        let value = '';
        for (let j of t1) {
          if (j.checked) {
            value = j.value;
          }
        }
        let type = 'single';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('dropdown')) {
        let key = document.getElementsByName(i.name)[0].previousElementSibling.children[0].innerText;
        let value = i.value;
        let type = 'single'
        t.push({
          key, value, type
        });
      }
    }

    let ans = await postUserBuilder({ project: id1, data: e, builder: adminBuilderId, inputs: t, combination, project: id1, projectName1: projectName, files3, files2, userBuilder: id });
    console.log(ans);

    if (ans.status) {
      notify(ans.status, "Updated Successfully");
    }
    else {
      notify(ans.status, ans.message);
    }
    setLoadFlag1(false);
  };

  return (
    <>
      {loadFlag1 && <Loader />}

      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                      <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Projects
                      </h1>

                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.82175 13.7748L10.5883 10L6.82175 6.22525L7.98132 5.06567L12.9157 10L7.98132 14.9344L6.82175 13.7748Z" fill="#7E8299" />
                      </svg>

                      <span>{title}</span>
                    </div>
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <h4>Select a combination</h4>

                    {/* <div className="tab-content d-block" id="myTabContent">
                      <div className="tab-pane d-block fade show" id='kt_customer_view_overview_activity_tab' role="tabpanel" aria-labelledby="#serf1">
                        <div className="parents_component">

                          {components.map((e, index) => {
                            if (Object.keys(toggle).includes(e._id) && toggle[e._id].length > 0) {
                              return (
                                <React.Fragment key={index}>
                                  <div onClick={() => {
                                    handleOpenChild(e._id);
                                  }} className="parent_component pc cursor-pointer" id={`c-${e._id}`}>
                                    <div className="pc1">
                                      <p>{e.title}</p>
                                    </div>
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </div>
                                  </div>

                                  <>
                                    {subComponent[e._id] && subComponent[e._id]?.length > 0 && subComponent[e._id].map((f, index1) => {
                                      if (toggle[e._id].includes(f._id)) {
                                        return (
                                          <div key={index1} className="child_component pc" id={`sc-${f._id}`}>
                                            <div className="pc1">
                                              <div className="form-check form-switch form-check-custom form-check-solid">
                                                <input onChange={() => {
                                                  handleCheckBox(`${f.title}`, index);
                                                  handleCheckBox1(f._id, e._id);
                                                }} className="form-check-input" type="checkbox" value="" id={`s-${f._id}`} defaultChecked={files3[e._id]?.includes(f._id)} />
                                              </div>
                                              <p className='subComponent'>{f.title}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </>
                                </React.Fragment>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div> */}

                    <div className="tab-content d-block" id="myTabContent">
                      <div className="tab-pane d-block fade show" id='kt_customer_view_overview_activity_tab' role="tabpanel" aria-labelledby="#serf1">
                        <div className="parents_component">

                          {components.map((e, index) => {
                            if (Object.keys(toggle).includes(e._id) && toggle[e._id].length > 0) {
                              return (
                                <React.Fragment key={index}>
                                  <div onClick={() => {
                                    handleOpenChild(e._id);
                                  }} className="parent_component pc cursor-pointer" id={`c-${e._id}`}>
                                    <div className="pc1">
                                      <p>{e.title}</p>
                                    </div>
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </div>
                                  </div>

                                  <div id={`scp-${e._id}`} style={{ display: 'flex', alignItems: 'flex-end', width: '100%', flexDirection: 'column' }}>
                                    {subComponent[e._id] && subComponent[e._id]?.length > 0 && subComponent[e._id].map((f, index1) => {
                                      if (toggle[e._id].includes(f._id)) {
                                        return (
                                          <div key={index1} className="child_component pc" id={`sc-${f._id}`}>
                                            <div className="pc1">
                                              <div className="form-check form-switch form-check-custom form-check-solid">
                                                <input onChange={() => {
                                                  handleCheckBox(`${f.title}`, index);
                                                  handleCheckBox1(f._id, e._id);
                                                }} className="form-check-input" type="radio" name={`togg${e._id}`} value="" id={`s-${f._id}`} defaultChecked={files3[e._id]?.includes(f._id)} />
                                              </div>
                                              <p className='subComponent'>{f.title}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>


                    <div className="youTube_counter mt-8">
                      <div className="counter_header">
                        <h2>{title}</h2>
                      </div>

                      <input className=' form-control conts' type="text" placeholder='Enter Project Name' name="projectName" onChange={(e) => setProjectName(e.target.value)} value={projectName} />

                      {!loadFlag ? <div className="counter_form">
                        <ReactFormGenerator
                          onSubmit={handleSubmit}
                          form_action={(e) => { e.preventDefault(); }}
                          form_method="POST"
                          answer_data={answerData}
                          data={data}
                        />
                      </div> : <div className="spinner-grow  d-block m-auto text-primary mt-4" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  )
};

export default EndUserEditable;
