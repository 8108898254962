import React,{useState} from 'react';
import HomeNavbar from '../Home/HomeNavbar';
import { NavLink } from 'react-router-dom';
import { useMain } from '../../hooks/useMain';
import { useNavigate } from 'react-router-dom';
const Signin = ({notify}) => {
    const { login,setUser } = useMain();
    const navigate = useNavigate();

    const [value, setValue] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const ans = await login(value);
        console.log(ans);
        notify(ans.status, ans.message);
        if (ans.status) {
            setUser(ans.user);
            localStorage.setItem('dolibo_user', JSON.stringify(ans.user));
            localStorage.setItem('dolibo_token', ans.token);
            
            if (ans.user.role === 'ADMIN') {
                navigate('/admin/dashboard');
            }
            else {
                navigate('/endUser/projectCatalog');
            }
        }
    }
    return (
        <>
            <HomeNavbar />
            <div className="sigin">
                <div className="svgd">
                    <NavLink to="/">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 24.6667C8.11996 24.6667 3.33329 19.8801 3.33329 14.0001C3.33329 8.12008 8.11996 3.33341 14 3.33341C19.88 3.33341 24.6666 8.12008 24.6666 14.0001C24.6666 19.8801 19.88 24.6667 14 24.6667ZM14 0.666748C6.62663 0.666748 0.666626 6.62675 0.666626 14.0001C0.666626 21.3734 6.62663 27.3334 14 27.3334C21.3733 27.3334 27.3333 21.3734 27.3333 14.0001C27.3333 6.62675 21.3733 0.666748 14 0.666748ZM17.4533 8.66675L14 12.1201L10.5466 8.66675L8.66663 10.5467L12.12 14.0001L8.66663 17.4534L10.5466 19.3334L14 15.8801L17.4533 19.3334L19.3333 17.4534L15.88 14.0001L19.3333 10.5467L17.4533 8.66675Z" fill="#3A3C40" />
                        </svg>
                    </NavLink>

                </div>
                <div className="signin_cards">
                    <div className="sigin_card">
                        <div className="signin_head">
                            <h2>Sign in</h2>
                            <p>Sign in with your email here.</p>
                        </div>
                        <form  onSubmit={handleSubmit}>
                            <div className="signin_input">
                                <input className='form-control'
                                    placeholder='Email'
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={value.email}
                                    defaultValue=""
                                    required
                                    autoComplete="email"
                                    autofocus=""
                                />
                            </div>
                            <div className="signin_input">
                                <input
                                    className='form-control'
                                    placeholder='Password'
                                    type="password"
                                    id=""
                                    onChange={handleChange}
                                    value={value.password}
                                    name="password"
                                    required
                                    autoComplete="current-password"
                                />
                            </div>
                            <div className="signin_btn">
                                <button>Sign in</button>
                            </div>
                        </form>
                        <div className="signin_hr">
                            <hr />
                        </div>
                        <div className="forgot_pass">
                            <p>Forgot password? <NavLink to="/forgotPassword"><span> Reset</span></NavLink> </p>
                            <p>Don't have an account? <NavLink to="/signup"> <span>Sign up</span> </NavLink> </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signin