import React from 'react'
import HomeNavbar from '../Home/HomeNavbar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMain } from '../../hooks/useMain';

const GetOtp = ({ notify }) => {
    const { sendOtp, submitOtp } = useMain();
    const navigate = useNavigate();

    const resend = async () => {
        let ans = await sendOtp({ email: JSON.parse(localStorage.getItem('b-reset')).email });
        console.log(ans);
        if (ans.status) {
            localStorage.setItem('b-reset', JSON.stringify({ email: JSON.parse(localStorage.getItem('b-reset')).email, otp: ans.otp }));
            notify(ans.status, ans.message);
        }
        else {
            notify(ans.status, ans.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let otp1='';
        let b1 = document.querySelectorAll('.signin_input');
        for(let i of b1)
        {
            otp1+=i.children[0].value;
        }

        const ans = await submitOtp({ otp: JSON.parse(localStorage.getItem('b-reset')).otp, otp1 });
        console.log(ans);

        if (ans.status) {
            notify(ans.status, ans.message);
            navigate("/reset-password");
        }
        else {
            notify(ans.status, ans.message);
        }
    };

    return (
        <>
            <HomeNavbar />
            <div className="sigin">
                <div className="svgd">
                    <NavLink to="/">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 24.6667C8.11996 24.6667 3.33329 19.8801 3.33329 14.0001C3.33329 8.12008 8.11996 3.33341 14 3.33341C19.88 3.33341 24.6666 8.12008 24.6666 14.0001C24.6666 19.8801 19.88 24.6667 14 24.6667ZM14 0.666748C6.62663 0.666748 0.666626 6.62675 0.666626 14.0001C0.666626 21.3734 6.62663 27.3334 14 27.3334C21.3733 27.3334 27.3333 21.3734 27.3333 14.0001C27.3333 6.62675 21.3733 0.666748 14 0.666748ZM17.4533 8.66675L14 12.1201L10.5466 8.66675L8.66663 10.5467L12.12 14.0001L8.66663 17.4534L10.5466 19.3334L14 15.8801L17.4533 19.3334L19.3333 17.4534L15.88 14.0001L19.3333 10.5467L17.4533 8.66675Z" fill="#3A3C40" />
                        </svg>
                    </NavLink>
                </div>

                <div className="signin_cards">
                    <div className="sigin_card">
                        <div className="signin_head">
                            <h2>Check your email</h2>
                            <p>We’ve sent a code to <span>{JSON.parse(localStorage.getItem('b-reset'))?.email}</span></p>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className='otp_screen'>
                                <div className="signin_input">
                                    <input className='form-control' maxLength="1" type="text" />
                                </div>
                                <div className="signin_input">
                                    <input className='form-control' maxLength="1" type="text" />
                                </div>
                                <div className="signin_input">
                                    <input className='form-control' maxLength="1" type="text" />
                                </div>
                                <div className="signin_input">
                                    <input className='form-control' maxLength="1" type="text" />
                                </div>
                            </div>

                            <div className="signin_btn">
                                <button>Verify</button>
                            </div>

                            <div className="verify_again">
                                <p>Didn’t get a code? <span onClick={resend}>Click to resend</span> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetOtp