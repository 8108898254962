import React from 'react';
import { NavLink } from 'react-router-dom';
import dolo from '../../images/dolo.png';
const HomeNavbar = () => {
  return (
    <>
      <div className="home-navbar">
        <nav className="t_navbar">
          <div className="nav_logo">
            <NavLink to="/"><img src={dolo} alt="" /></NavLink>
          </div>
          <div className="reg_page">
            <div className="signin">
              <NavLink to="/signin"><span>Sign in</span></NavLink>
            </div>
            <div className='sign_ip'>
              <NavLink to="/signup"><button>Sign up</button></NavLink>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default HomeNavbar