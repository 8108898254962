import React from 'react'
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
// import rtyu from '../../../images/rtyu.png'
const EndUserProject = () => {
  return (
    <>
          <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

                    <UserNavbar/>
                    <div
                        className="app-wrapper flex-column flex-row-fluid"
                        id="kt_app_wrapper"
                    >

                        <UserSidebar/>
                        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                            <div className="d-flex flex-column flex-column-fluid">
                                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                                    <div
                                        id="kt_app_toolbar_container"
                                        className="app-container container-xxl d-flex flex-stack"
                                    >
                                        <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                                            <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                                Projects
                                            </h1>

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.82175 13.7748L10.5883 10L6.82175 6.22525L7.98132 5.06567L12.9157 10L7.98132 14.9344L6.82175 13.7748Z" fill="#7E8299" />
                                            </svg>

                                            <span>YouTube Counter and Clock</span>


                                        </div>

                                    </div>
                                </div>
                                <div id="kt_app_content" className="app-content flex-column-fluid">
                                    <div
                                        id="kt_app_content_container"
                                        className="app-container container-xxl"
                                    >

                                        <div className="youTube_counter mt-8">
                                            <div className="counter_header">
                                                <h2>YouTube Counter and Clock</h2>
                                            </div>
                                            <div className="counter_form">
                                                <form>
                                                    <div className="counter_input">
                                                        <label htmlFor="">Project Title</label>
                                                        <input className='form-control mt-2' type="text" placeholder='YouTube Counter and Clock' />
                                                    </div>
                                                    {/* <div className="counter_input">
                                                        <label htmlFor="">Project Title</label>
                                                        <input className='form-control mt-2' type="text" placeholder='YouTube Counter and Clock' />
                                                    </div> */}

                                                    <div className="can_save_btn">
                                                        <div className="cancel">
                                                            <button>Cancel</button>
                                                        </div>
                                                        <div className="save">
                                                            <button><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.79995 10.9L1.59995 6.70005L0.199951 8.10005L5.79995 13.7L17.8 1.70005L16.4 0.300049L5.79995 10.9Z" fill="white" />
                                                            </svg>
                                                            <span>Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>
                            <div id="kt_app_footer" className="app-footer">
                                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                                    <div className="text-dark order-2 order-md-1">
                                        <span className="text-muted fw-semibold me-1">2023©</span>
                                        <a
                                            href="#"
                                            target="_blank"
                                            className="text-gray-800 text-hover-primary"
                                        >
                                            Dolibo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <i className="ki-duotone ki-arrow-up">
                    <span className="path1" />
                    <span className="path2" />
                </i>
            </div>
    </>
  )
}

export default EndUserProject