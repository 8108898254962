import React, { useState, useEffect } from 'react';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMain } from '../../../hooks/useMain';
import OutsideClickHandler from 'react-outside-click-handler';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from '../../../Utils/Loader';
const ShowProject = ({ notify }) => {

  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState({
    query: ""
  });

  let tyer = {
    display: toggle ? "block" : "none"
  }

  const tabSet = () => {
    setToggle(!toggle);
    alert("hlw");
  };

  const func = () => {
    document.querySelector(".menu-sub").classList.toggle("show");
  };

  let { getProjects, deleteProject, updateProjectStatus } = useMain();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [loadFlag, setLoadFlag] = useState(false);
  const [updateNum, setUpdateNum] = useState(-1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [loadFlag1, setLoadFlag1] = useState(true);
  useEffect(() => {
    getData();
  }, [refreshFlag, page, perPage]);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData();
  };

  const getData = async () => {
    setLoadFlag1(true);
    const ans = await getProjects('', value.query, page, perPage);
    console.log(ans);
    setData(ans.data);
    setTotal(ans.count);
    setLoadFlag1(false);
  };

  const deleteProject1 = async (id) => {
    confirmAlert({
      title: 'Are you sure to delete this data?',
      message: 'All related data to this will be deleted',
      buttons: [
        {
          label: 'Yes, Go Ahead!',
          style: {
            background: "#FF5449"
          },
          onClick: async () => {
            const ans = await deleteProject(id);
            console.log(ans);
            if (ans.status) {
              // alert(ans.message);
              notify(ans.status, ans.message);
              setRefreshFlag(!refreshFlag);
            }
            else {
              alert('Something went wrong! ');
            }
          }
        },
        {
          label: 'Cancel',

          onClick: () => null
        }
      ]
    });
  };

  const updateStatus = async (id, status, index) => {
    setUpdateNum(index);
    setLoadFlag(true);
    const ans = await updateProjectStatus({ id, status });
    notify(ans.status, ans.message);
    if (ans.status) {
      setRefreshFlag(!refreshFlag);
    }
    setUpdateNum(-1);
    setLoadFlag(false);
  };
  return (
    <>

      {/* <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

                    <UserNavbar />
                    <div
                        className="app-wrapper flex-column flex-row-fluid"
                        id="kt_app_wrapper"
                    >

                        <UserSidebar />
                        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                            <div className="d-flex flex-column flex-column-fluid">
                                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                                    <div
                                        id="kt_app_toolbar_container"
                                        className="app-container container-xxl d-flex flex-stack"
                                    >
                                    </div>
                                </div>
                                <div id="kt_app_content" className="app-content flex-column-fluid">
                                    <div
                                        id="kt_app_content_container"
                                        className="app-container container-xxl"
                                    >
                                        <div className="user_profil user_profiling">
                                            <div className="header_profile">
                                                <h2>Projects</h2>
                                            </div>
                                            <div className="card mt-5">
                                              
                                                <div className="card-header border-0 pt-6">
                                                  
                                                    <div className="card-title w-100">
                                                       

                                                        <div className='doat'>
                                                            <h2>Active Projects</h2>
                                                            <input type="search" placeholder='Search' />
                                                        </div>

                                                        
                                                    </div>
                                                   

                                                    
                                                </div>
                                               
                                                <div className="card-body pt-0">
                                                   
                                                    <div
                                                        id="kt_customers_table_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                                                    >
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                                                id="kt_customers_table"
                                                            >
                                                                <thead className='treats'>
                                                                    <tr className="text-start tickna fw-bold fs-7  gs-0">

                                                                        <th
                                                                            className="min-w-125px sorting fory"
                                                                            tabIndex={0}
                                                                            aria-controls="kt_customers_table"
                                                                            rowSpan={1}
                                                                            colSpan={1}
                                                                            aria-label="Customer Name: activate to sort column ascending"
                                                                        
                                                                        >
                                                                            Title
                                                                        </th>
                                                                        <th
                                                                            className="min-w-125px sorting fory"
                                                                            tabIndex={0}
                                                                            aria-controls="kt_customers_table"
                                                                            rowSpan={1}
                                                                            colSpan={1}
                                                                            aria-label="Email: activate to sort column ascending"
                                                                        
                                                                        >
                                                                            Created By
                                                                        </th>
                                                                        <th
                                                                            className="min-w-125px sorting fory"
                                                                            tabIndex={0}
                                                                            aria-controls="kt_customers_table"
                                                                            rowSpan={1}
                                                                            colSpan={1}
                                                                            aria-label="Email: activate to sort column ascending"
                                                                        
                                                                        >
                                                                            Creation Date
                                                                        </th>
                                                                        <th
                                                                            className="min-w-125px sorting fory"
                                                                            tabIndex={0}
                                                                            aria-controls="kt_customers_table"
                                                                            rowSpan={1}
                                                                            colSpan={1}
                                                                            aria-label="Payment Method: activate to sort column ascending"
                                                                       
                                                                        >
                                                                            Action
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody className="fw-semibold text-gray-600">
                                                                    <tr>
                                                                       
                                                                        <td>
                                                                            <a
                                                                                href="#!"
                                                                                className=" odie mb-1"
                                                                            >
                                                                                YouTube Counter and Clock
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                TheMadFish
                                                                            </a>
                                                                        </td>

                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                August 18, 2022
                                                                            </a>
                                                                        </td>

                                                                        <td className='masting_cards' data-filter="mastercard">


                                                                            <div className="dropdown">

                                                                                <svg className="dropdown-toggle dropdown-menu-left"

                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                                                                    <path d="M11 13.6667C10.2666 13.6667 9.66663 14.2667 9.66663 15.0001C9.66663 15.7334 10.2666 16.3334 11 16.3334C11.7333 16.3334 12.3333 15.7334 12.3333 15.0001C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2666 13.6667 17.6666 14.2667 17.6666 15.0001C17.6666 15.7334 18.2666 16.3334 19 16.3334C19.7333 16.3334 20.3333 15.7334 20.3333 15.0001C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2666 13.6667 13.6666 14.2667 13.6666 15.0001C13.6666 15.7334 14.2666 16.3334 15 16.3334C15.7333 16.3334 16.3333 15.7334 16.3333 15.0001C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                                                                </svg>

                                                                                <ul data-popper-placement="left-start" className="dropdown-menu dropdowns_menu drp_menu">
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.71569 5.51667L10.4824 6.28333L2.93236 13.8333H2.16569V13.0667L9.71569 5.51667ZM12.7157 0.5C12.5074 0.5 12.2907 0.583333 12.1324 0.741667L10.6074 2.26667L13.7324 5.39167L15.2574 3.86667C15.5824 3.54167 15.5824 3.01667 15.2574 2.69167L13.3074 0.741667C13.1407 0.575 12.9324 0.5 12.7157 0.5ZM9.71569 3.15833L0.499023 12.375V15.5H3.62402L12.8407 6.28333L9.71569 3.15833Z" fill="#5E6278" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Edit
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M10.3333 7.16667V12.975L6.16658 11.1917L1.99992 12.975V2.16667H6.99992V0.5H1.99992C1.08325 0.5 0.333252 1.25 0.333252 2.16667V15.5L6.16658 13L11.9999 15.5V7.16667H10.3333ZM13.6666 3.83333H11.9999V5.5H10.3333V3.83333H8.66658V2.16667H10.3333V0.5H11.9999V2.16667H13.6666V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Darft
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z" fill="#5E6278" />
                                                                                            </svg>


                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Publish
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2 view_details1'>
                                                                                        <div>
                                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.33341 5.5V13.8333H2.66675V5.5H9.33341ZM8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333H8.91675L8.08341 0.5ZM11.0001 3.83333H1.00008V13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Delete
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </ul>
                                                                            </div>


                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                   
                                                                        <td>
                                                                            <a
                                                                                href="#!"
                                                                                className=" odie mb-1"
                                                                            >
                                                                                YouTube Counter and Clock
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                TheMadFish
                                                                            </a>
                                                                        </td>

                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                August 18, 2022
                                                                            </a>
                                                                        </td>
                                                                        <td data-filter="mastercard">


                                                                            <div className="dropdown">

                                                                                <svg className="dropdown-toggle dropdown-menu-left"

                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                                                                    <path d="M11 13.6667C10.2666 13.6667 9.66663 14.2667 9.66663 15.0001C9.66663 15.7334 10.2666 16.3334 11 16.3334C11.7333 16.3334 12.3333 15.7334 12.3333 15.0001C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2666 13.6667 17.6666 14.2667 17.6666 15.0001C17.6666 15.7334 18.2666 16.3334 19 16.3334C19.7333 16.3334 20.3333 15.7334 20.3333 15.0001C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2666 13.6667 13.6666 14.2667 13.6666 15.0001C13.6666 15.7334 14.2666 16.3334 15 16.3334C15.7333 16.3334 16.3333 15.7334 16.3333 15.0001C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                                                                </svg>

                                                                                <ul data-popper-placement="left-start" className="dropdown-menu dropdowns_menu drp_menu">
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.71569 5.51667L10.4824 6.28333L2.93236 13.8333H2.16569V13.0667L9.71569 5.51667ZM12.7157 0.5C12.5074 0.5 12.2907 0.583333 12.1324 0.741667L10.6074 2.26667L13.7324 5.39167L15.2574 3.86667C15.5824 3.54167 15.5824 3.01667 15.2574 2.69167L13.3074 0.741667C13.1407 0.575 12.9324 0.5 12.7157 0.5ZM9.71569 3.15833L0.499023 12.375V15.5H3.62402L12.8407 6.28333L9.71569 3.15833Z" fill="#5E6278" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Edit
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M10.3333 7.16667V12.975L6.16658 11.1917L1.99992 12.975V2.16667H6.99992V0.5H1.99992C1.08325 0.5 0.333252 1.25 0.333252 2.16667V15.5L6.16658 13L11.9999 15.5V7.16667H10.3333ZM13.6666 3.83333H11.9999V5.5H10.3333V3.83333H8.66658V2.16667H10.3333V0.5H11.9999V2.16667H13.6666V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Darft
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z" fill="#5E6278" />
                                                                                            </svg>


                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Publish
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2 view_details1'>
                                                                                        <div>
                                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.33341 5.5V13.8333H2.66675V5.5H9.33341ZM8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333H8.91675L8.08341 0.5ZM11.0001 3.83333H1.00008V13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Delete
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </ul>
                                                                            </div>


                                                                        </td>

                                                                    </tr>

                                                                    <tr>
                                    
                                                                        <td>
                                                                            <a
                                                                                href="#!"
                                                                                className=" odie mb-1"
                                                                            >
                                                                                YouTube Counter and Clock
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                TheMadFish
                                                                            </a>
                                                                        </td>

                                                                        <td>
                                                                            <a href="#" className="text-gray-600 odd1  mb-1">
                                                                                August 18, 2022
                                                                            </a>
                                                                        </td>

                                                                        <td data-filter="mastercard">


                                                                            <div className="dropdown">

                                                                                <svg className="dropdown-toggle dropdown-menu-left"

                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                                                                    <path d="M11 13.6667C10.2666 13.6667 9.66663 14.2667 9.66663 15.0001C9.66663 15.7334 10.2666 16.3334 11 16.3334C11.7333 16.3334 12.3333 15.7334 12.3333 15.0001C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2666 13.6667 17.6666 14.2667 17.6666 15.0001C17.6666 15.7334 18.2666 16.3334 19 16.3334C19.7333 16.3334 20.3333 15.7334 20.3333 15.0001C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2666 13.6667 13.6666 14.2667 13.6666 15.0001C13.6666 15.7334 14.2666 16.3334 15 16.3334C15.7333 16.3334 16.3333 15.7334 16.3333 15.0001C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                                                                </svg>

                                                                                <ul data-popper-placement="left-start" className="dropdown-menu dropdowns_menu drp_menu">
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.71569 5.51667L10.4824 6.28333L2.93236 13.8333H2.16569V13.0667L9.71569 5.51667ZM12.7157 0.5C12.5074 0.5 12.2907 0.583333 12.1324 0.741667L10.6074 2.26667L13.7324 5.39167L15.2574 3.86667C15.5824 3.54167 15.5824 3.01667 15.2574 2.69167L13.3074 0.741667C13.1407 0.575 12.9324 0.5 12.7157 0.5ZM9.71569 3.15833L0.499023 12.375V15.5H3.62402L12.8407 6.28333L9.71569 3.15833Z" fill="#5E6278" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Edit
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M10.3333 7.16667V12.975L6.16658 11.1917L1.99992 12.975V2.16667H6.99992V0.5H1.99992C1.08325 0.5 0.333252 1.25 0.333252 2.16667V15.5L6.16658 13L11.9999 15.5V7.16667H10.3333ZM13.6666 3.83333H11.9999V5.5H10.3333V3.83333H8.66658V2.16667H10.3333V0.5H11.9999V2.16667H13.6666V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Darft
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2'>
                                                                                        <div>
                                                                                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z" fill="#5E6278" />
                                                                                            </svg>


                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Publish
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className='view_details mt-2 view_details1'>
                                                                                        <div>
                                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.33341 5.5V13.8333H2.66675V5.5H9.33341ZM8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333H8.91675L8.08341 0.5ZM11.0001 3.83333H1.00008V13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333Z" fill="#5E6278" />
                                                                                            </svg>

                                                                                        </div>
                                                                                        <li>
                                                                                            <a className="dropdown-item" >
                                                                                                Delete
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </ul>
                                                                            </div>


                                                                        </td>

                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                   
                                                </div>
                                               
                                            </div>
                                        </div>

                                        

                                    </div>
                                </div>
                            </div>
                            <div id="kt_app_footer" className="app-footer">
                                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                                    <div className="text-dark order-2 order-md-1">
                                        <span className="text-muted fw-semibold me-1">2023©</span>
                                        <a
                                            href="#"
                                            target="_blank"
                                            className="text-gray-800 text-hover-primary"
                                        >
                                            Dolibo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <i className="ki-duotone ki-arrow-up">
                    <span className="path1" />
                    <span className="path2" />
                </i>
            </div> */}

      {loadFlag1 && <Loader />}
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar />

          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <UserSidebar />

            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                      <h1 className="page-heading page-heading1 d-flex   flex-column justify-content-center my-0">
                        Projects
                      </h1>

                    </div>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                      <NavLink to="/endUser/createUserProject" className="btn btn-thin btn-sm  btn-primary">
                        Create New Project
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="card card-flush card-body2 card-slush">
                      {/*begin::Card header*/}
                      <div className="card-header pt-8">
                        <div className="card-title block card-title1">
                          {/*begin::Search*/}
                          <div className="active_project">
                            <h2>Active Projects</h2>
                            <form onKeyUp={handleSearch} className="form_search">
                              <input type="search" onChange={handleChange} name='query' value={value.query} className='form-control' placeholder='search' />
                            </form>
                          </div>
                          {/*end::Search*/}
                        </div>
                      </div>

                      <div className="card-body card-body4 card-si table-responsive">
                        <table
                          id="kt_file_manager_list"
                          data-kt-filemanager-table="files"
                          className="table align-middle table-row-dashed fs-6 gy-5"
                        >
                          <thead className="suching">
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 tr-fash">
                              <th className="name44">Project name</th>
                              <th className="name44">Created By</th>
                              <th className="name44">Creation Date</th>
                              {/* <th className="name44">Status</th> */}
                              <th className="name44">Action</th>
                            </tr>
                          </thead>

                          <tbody className="fw-semibold text-gray-600">
                            {data.map((e, index) => {
                              return (
                                <tr key={index} className="tri11">
                                  <td>
                                    <NavLink style={{ color: 'black' }} to={`/endUser/updateProject/${e._id}`}>
                                      {e.title}
                                    </NavLink>
                                  </td>
                                  <td>{e.createdBy.name}</td>
                                  <td>
                                    {new Date(Number(e.ts)).toLocaleDateString()}
                                  </td>

                                  {/* <td className='cursor-pointer' onClick={() => {
                                    updateStatus(e._id, e.status === "true" ? "false" : "true", index);
                                  }}>
                                    <div className="status-baring">
                                      {loadFlag && index === updateNum ? <p style={{ fontSize: "13px" }}>Updating ..</p> : <>
                                        <div className={e.status === "false" ? "reding" : "green"} />
                                        <p>{e.status === "false" ? "inactive" : "active"}</p>
                                      </>}

                                      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.06 0.529999L4 3.58333L0.94 0.529999L0 1.47L4 5.47L8 1.47L7.06 0.529999Z" fill="#7E8299" />
                                      </svg>
                                    </div>
                                  </td> */}

                                  <td>
                                    <OutsideClickHandler
                                      onOutsideClick={() => {
                                        if (!document.getElementById(`action_box${index}`).classList.contains('d-none')) {
                                          document.getElementById(`action_box${index}`).classList.add('d-none');
                                        }
                                      }}
                                    >
                                      <svg className='cursor-pointer' onClick={() => {
                                        document.getElementById(`action_box${index}`).classList.toggle('d-none');
                                      }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                        <path d="M11 13.6667C10.2667 13.6667 9.66667 14.2667 9.66667 15C9.66667 15.7333 10.2667 16.3333 11 16.3333C11.7333 16.3333 12.3333 15.7333 12.3333 15C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2667 13.6667 17.6667 14.2667 17.6667 15C17.6667 15.7333 18.2667 16.3333 19 16.3333C19.7333 16.3333 20.3333 15.7333 20.3333 15C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2667 13.6667 13.6667 14.2667 13.6667 15C13.6667 15.7333 14.2667 16.3333 15 16.3333C15.7333 16.3333 16.3333 15.7333 16.3333 15C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                      </svg>

                                      <div id={`action_box${index}`} className='d-none action_box'>
                                        <div onClick={() => {
                                          navigate(`/endUser/updateProject/${e._id}`);
                                        }}>
                                          edit
                                        </div>
                                        <div onClick={() => {
                                          deleteProject1(e._id);
                                        }}>
                                          delete
                                        </div>
                                      </div>
                                    </OutsideClickHandler>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div style={{ width: 'fit-content', margin: 'auto' }} className="view_all">
                          <button disabled={page === 1} className='btn btn-primary btn1' onClick={() => {
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}>Previous</button>
                          <span className='btn2'>Page {page}</span>
                          <button disabled={(page * perPage) >= total} className='btn btn-primary btn3' onClick={() => {
                            if ((page * perPage) < total) {
                              setPage(page + 1);
                            }
                          }}>Next</button>
                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Card body*/}
                    </div>

                  </div>
                </div>
              </div>
              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>

    </>
  )
}

export default ShowProject