import React from "react";
import { ReactFormBuilder } from "react-form-builder2";
import { get } from "./requests";

const FormBuilderPage = (props) => {
  const items = [
    { key: "Label" },
    { key: "TextInput" },
    { key: "NumberInput" },
    { key: "Checkboxes" },
    { key: "RadioButtons", name: 'Radio Buttons', },
    { key: "Dropdown" },
  ];
  // console.log(props.builderData)

  return (
    <>
      <ReactFormBuilder
        data={props?.builderData}
        // saveUrl={() => { get("/api/formdata"); }}
        toolbarItems={items}
        locale="en"
        saveAlways={true}
      />
    </>
  );
 
};

export default FormBuilderPage;
