import React from 'react'
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import { useMain } from '../../../hooks/useMain';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import JSZip, { files } from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import { isYoutubeChannelUrl } from '../BuildProject/channel';
import doliboShort from '../../../images/doliboShort.png';
import { Highlight, themes } from "prism-react-renderer";
import moment from 'moment-timezone';
import {StlViewer} from "react-stl-viewer";

const zip = new JSZip();

const cartesian = (args) => {
  var r = [], max = args.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max)
        r.push(a);
      else
        helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
};

function downloadURI(uri, name = '') {
  var link = document.createElement("a");

  link.setAttribute('download', name);
  link.href = uri;
  link.target = "_blank"
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function getTimezoneOffsetByRegionInSeconds(region) {
  const offsetInMinutes = moment.tz(region).utcOffset();
  const offsetInSeconds = offsetInMinutes * 60;
  return offsetInSeconds;
}

const style = {
  position:"absolute",
  top: "50%",
  left: "0px",
  transform:"translateY(-50%)",
  width: '100%',
  height: '100vh',
};

// const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";

const BuildProject1 = ({ notify }) => {
  let { getBuilders, getProjects, getUserBuilders, postUserBuilder, getFiles, getSubComponents, getComponents, addProjectToFav } = useMain();

  const navigate = useNavigate();
  const { id, comb } = useParams();

  const [data, setData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [project, setProject] = useState({});
  const [loadFlag, setLoadFlag] = useState(true);
  const [userBuilderId, setUserBuilderId] = useState('');
  const [adminBuilderId, setAdminBuilderId] = useState('');
  const [files2, setFiles2] = useState('');
  var [userBuilder, setUserBuilder] = useState({});
  const [fileArr, setFileArr] = useState([]);
  const [projectName, setProjectName] = useState('');

  const [components, setComponents] = useState([]);
  const [subComponent, setSubComponent] = useState({});
  const [files3, setFiles3] = useState({});
  const [toggle, setToggle] = useState({});
  const [refreshFlag1, setRefreshFlag1] = useState(false);

  const [channelName, setChannelName] = useState({
    ytChannelUrl: "https://youtube.com/@HackMakeMod?si=e1kN7nteNqtQSCSP"
  });
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState({});
  const [flag1, setFlag1] = useState(false);
  const [code, setCode] = useState("");

  const handleCheckBox = (name, index) => {
    setFiles2(() => {
      return { ...files2, [index]: [name] };
    });
  };

  const [data4, setData4] = useState([]);

  useEffect(() => {
    getData3();
    addProjectToFav1();
  }, [id])

  const addProjectToFav1 = async () => {
    const ans = await addProjectToFav({ projectId: id, type: "add" });
    console.log(ans);
    localStorage.setItem('dolibo_user', JSON.stringify(ans.updateUser));
  };

  const getData3 = async () => {
    let ans = await getProjects(id);
    console.log(ans.data[0])
    setData4(ans.data[0]);
  };

  const handleCheckBox1 = (name, index) => {
    setFiles3(() => {
      return { ...files3, [index]: [name] };
    });
  };

  const getChannelData = async (ytChannelUrl) => {
    const resp = await fetch(`https://dev.dolibo.com/builder/getYoutubeData?ytChannelUrl=${ytChannelUrl}`, {
      // const resp = await fetch(`http://localhost:5000/builder/getYoutubeData?ytChannelUrl=${ytChannelUrl}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'token': localStorage.getItem('dolibo_token')
      }
    });
    const data = await resp.json();
    return data;
  };

  const handleChannel = async () => {
    // console.log(channelName.ytChannelUrl);
    setFlag1(true);
    const res = await getChannelData(channelName.ytChannelUrl);
    setData2(res?.data);
    setFlag1(false);
  };

  const isDisabled = useMemo(() => {
    if (!channelName.ytChannelUrl || (channelName.ytChannelUrl && !isYoutubeChannelUrl(channelName.ytChannelUrl))) return true;
    return false;
  }, [channelName.ytChannelUrl]);

  const handleOpenChild = async (id) => {
    if (document.getElementById(`scp-${id}`).classList.contains('first-flag')) {
      document.getElementById(`scp-${id}`).classList.toggle('com-d-flag');
    }
    else {
      document.getElementById(`scp-${id}`).classList.add('first-flag');
      // setLoadFlag(true);
      let ans = await getSubComponents(id);
      if (ans.data.length === 0) {
        notify(true, 'No sub-components found!');
      }
      setSubComponent({ ...subComponent, [id]: ans.data });
      // setLoadFlag(false);
    }

    // if (subComponent[id]) {
    //   let t = subComponent;
    //   delete t[id];
    //   setSubComponent(t);
    //   setRefreshFlag1(!refreshFlag1);
    // }
    // else {
    //   setLoadFlag(true);
    //   let ans = await getSubComponents(id);
    //   if (ans.data.length === 0) {
    //     notify(true, 'No sub-components found!');
    //   }
    //   setSubComponent({ ...subComponent, [id]: ans.data });
    //   setLoadFlag(false);
    // }
  };

  const download = (item) => {
    return axios.get(item.url, { responseType: "blob" }).then((resp) => {
      zip.file(item.name, resp.data);
    });
  };

  const downloadAll = (flag = false) => {
    if (flag) {
      fileArr.splice(0, 1);
      fileArr.splice(0, 1);
    }

    const arrOfFiles = fileArr.map((item) => download(item));
    Promise.all(arrOfFiles)
      .then(() => {
        //when all promises resolved - save zip file
        zip.generateAsync({ type: "blob" }).then(function (blob) {
          saveAs(blob, "setup.zip");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(comb);
  const getData = async () => {
    setLoadFlag(true);
    let ans3 = await getFiles(id);  // Files
    let ans2 = await getBuilders(id);  // Admin builder
    let ans1 = await getProjects(id);  // Project

    let ans = await getUserBuilders(id, JSON.parse(localStorage.getItem("dolibo_user"))._id, '', comb)  // User builder

    setToggle(ans3.data[0].toggle);
    let ans4 = await getComponents(id);  // Components
    setComponents(ans4.data);

    // console.log(ans3);
    if (ans2.data.length === 0) {
      notify(true, 'No Builder Found');
      setLoadFlag(false);
      navigate(-1);
      return;
    }

    setData(ans2.data[0].data);
    setData3(ans2.data[0]);
    setAdminBuilderId(ans2.data[0]._id);

    if (ans.data && ans.data[0]) {
      console.log(ans.data[0]);
      setChannelName({ ytChannelUrl: ans.data[0]?.ytInfo });
      setFiles3(ans.data[0].files3);
      setFiles2(ans.data[0].files2);
      setProjectName(ans.data[0].projectName1);
      setUserBuilder(ans.data[0]);

      setFlag1(true);
      const res = await getChannelData(ans.data[0]?.ytInfo);
      setData2(res?.data);
      setFlag1(false);

      const aa = await fetch(ans.data[0]?.codeFile?.url, {
        method: 'GET'
      });

      const bb = await aa.text();
      // console.log(bb);
      setCode(bb);

      setFileArr([{
        name: 'wirediagram.' + ans.data[0]?.wireFile?.url.split('/')[ans.data[0]?.wireFile?.url.split('/').length - 1].split('.')[ans.data[0]?.wireFile?.url.split('/')[ans.data[0]?.wireFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data[0]?.wireFile?.url
      }, {
        name: 'enclosure.' + ans.data[0]?.enclosureFile?.url.split('/')[ans.data[0]?.enclosureFile?.url.split('/').length - 1].split('.')[ans.data[0]?.enclosureFile?.url.split('/')[ans.data[0]?.enclosureFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data[0]?.enclosureFile?.url
      }, {
        name: 'codefile.' + ans.data[0]?.codeFile?.url.split('/')[ans.data[0]?.codeFile?.url.split('/').length - 1].split('.')[ans.data[0]?.codeFile?.url.split('/')[ans.data[0]?.codeFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data[0]?.codeFile?.url
      }]);
      // document.getElementById('down-btn').click();

      setUserBuilderId(ans.data[0]._id);
      setAnswerData(ans.data[0].data);
    }

    setProject(ans1.data[0]);
    setLoadFlag(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleSubmit = async (e) => {
    // let p = document.querySelectorAll('.parent_component').length;
    // if (files2 === "" || Object.keys(files2).filter(x => files2[x].length > 0).length !== p) {
    //   notify(false, 'Please select a valid combination from each component.');
    //   return;
    // }

    // for (let i of Object.values(files2)) {
    //   if (i.length > 1) {
    //     notify(false, 'Please select a valid combination. You can only select 1 Sub Component from each Component');
    //     return;
    //   }
    // }

    // let tp = [];
    // for (let i of cartesian(Object.values(files2))) {
    //   tp.push(String(i.map(x => ' ' + x)));
    // }
    // let combination = tp[0];
    let combination = comb;

    var display_modules = {
      Time: false,
      Date: false,
      Day: false,
      Channel: false,
      Subs: false,
      Views: false
    };

    const length = {
      Kilometer: false,
      Meter: false,
      Centimeter: false,
      Millimeter: false,
      Mile: false,
      Yard: false,
      Foot: false,
      FootAndInch: false,
      Inch: false,
      InchFractional: false
    };

    const mass = {
      MetricTon: false,
      Kilogram: false,
      Gram: false,
      Milligram: false,
      ImperialTon: false,
      USTon: false,
      Pound: false,
      Ounce: false
    };

    const speed = {
      MPH: false,
      FPS: false,
      MPS: false,
      KPH: false,
      Knots: false
    };

    const Temperature = {
      Celsius: false,
      Fahrenheit: false,
      Kelvin: false
    };

    let t = [];
    let ind = 0;
    for (let i of e) {
      if (i.name.includes('text_input')) {
        let key = document.getElementsByName(i.name)[0].previousElementSibling.children[0].innerText;
        let value = document.getElementsByName(i.name)[0].value;
        let type = 'single';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('checkbox')) {
        let key = '';
        let t1 = [];

        if (data3?.youtubeFlag === "true") {
          key = 'Display Modules';
        }
        else if (project?.title === "Unit Converter") {
          if (ind === 0) {
            key = "Length"
          }
          else if (ind === 1) {
            key = "Mass";
          }
          else if (ind === 2) {
            key = "Speed";
          }
          else if (ind === 3) {
            key = "Temperature";
          }
        }

        if (i.value.length > 0) {
          // console.log('yess');
          key = document.getElementsByName(`option_${i.value[0]}`)[0].parentNode.parentNode.children[0].children[0].innerText;

          for (let j of i.value) {
            let label = document.getElementsByName(`option_${j}`)[0].nextElementSibling.innerText;
            let val = document.getElementsByName(`option_${j}`)[0].value;
            // console.log(i.value);
            if (data3?.youtubeFlag === "true") {
              display_modules[label] = val === "true";
            }
            else if (project?.title === "Unit Converter") {
              if (label === "Foot and Inch") {
                label = "FootAndInch";
              }
              label = label.replaceAll(' ', '');
              if (key === "Length") {
                length[label] = true;
              }
              else if (key === "Mass") {
                mass[label] = true;
              }
              else if (key === "Speed") {
                speed[label] = true;
              }
              else if (key === "Temperature") {
                Temperature[label] = true;
              }
            }
            else {
              t1.push({
                label, val
              });
            }
          }
          console.log(t1);
        }

        let value = t1;
        if (data3?.youtubeFlag === "true") {
          value = display_modules
        }
        else if (project?.title === "Unit Converter") {
          if (key === "Length") {
            value = length;
          }
          else if (key === "Mass") {
            value = mass;
          }
          else if (key === "Speed") {
            value = speed;
          }
          else if (key === "Temperature") {
            value = Temperature;
          }
        }

        let type = 'multiple';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('radiobutton')) {
        let key = document.getElementsByName(i.name)[0].parentNode.previousElementSibling.children[0].innerText;
        let t1 = document.getElementsByName(i.name);
        let value = '';
        for (let j of t1) {
          if (j.checked) {
            value = j.value;
          }
        }
        let type = 'single';
        t.push({
          key, value, type
        });
      }
      else if (i.name.includes('dropdown')) {
        let key = document.getElementsByName(i.name)[0].previousElementSibling.children[0].innerText;
        let value = i.value;
        let type = 'single'
        t.push({
          key, value, type
        });
      }
      ind++;
    }

    // console.log(t);

    var youtubeData;

    if (data3?.youtubeFlag === "true") {
      let q = t.find(x => x.key === 'Seconds').value;
      let q1 = t.find(x => x.key === 'Transition Type').value;
      let q2 = t.find(x => x.key === "Display Modules").value;
      let q3 = t.find(x => x.key === "Timezone").value;
      // console.log(q3);
      // console.log(`${q3.split('_')[0]}/${q3.split('_')[1]}`);
      const offset = getTimezoneOffsetByRegionInSeconds(q3);

      youtubeData = {
        "timezone": offset,
        "dispTime": q2['Time'].toString(),
        "dispDate": q2['Date'].toString(),
        "dispDay": q2['Day'].toString(),
        "dispChannel": q2['Channel'].toString(),
        "dispSubs": q2['Subs'].toString(),
        "dispViews": q2['Views'].toString(),
        "transitionTime": q,
        "transitionType": q1 === "fade" ? 0 : 1
      };
    }
    // console.log(youtubeData);

    let ans = await postUserBuilder({ project: id, data: e, builder: adminBuilderId, inputs: t, combination, projectName1: projectName, files3, files2, ytInfo: channelName.ytChannelUrl, youtubeData: data3?.youtubeFlag === "true" ? youtubeData : {} });
    // console.log(ans);

    if (ans.status) {
      setUserBuilder(ans.data);

      setFileArr([{
        name: 'wirediagram.' + ans.data?.wireFile?.url.split('/')[ans.data?.wireFile?.url.split('/').length - 1].split('.')[ans.data?.wireFile?.url.split('/')[ans.data?.wireFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data?.wireFile?.url
      }, {
        name: 'enclosure.' + ans.data?.enclosureFile?.url.split('/')[ans.data?.enclosureFile?.url.split('/').length - 1].split('.')[ans.data?.enclosureFile?.url.split('/')[ans.data?.enclosureFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data?.enclosureFile?.url
      }, {
        name: 'codefile.' + ans.data?.codeFile?.url.split('/')[ans.data?.codeFile?.url.split('/').length - 1].split('.')[ans.data?.codeFile?.url.split('/')[ans.data?.codeFile?.url.split('/').length - 1].split('.').length - 1],
        url: ans.data?.codeFile?.url
      }]);
      // userBuilder = ans.data;
      const aa = await fetch(ans.data?.codeFile?.url, {
        method: 'GET'
      });
      const bb = await aa.text();
      // console.log(bb);
      setCode(bb);

      notify(ans.status, "Submitted Successfully");
      // document.getElementById('down-btn').click();
    }
    else {
      notify(ans.status, ans.message);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <UserSidebar />

            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <NavLink to="/endUser/projectCatalog"> <div className="page-title align-items-center d-flex  justify-content-center flex-wrap me-3">
                      <h1 className="page-heading user_son d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Projects
                      </h1>

                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.82175 13.7748L10.5883 10L6.82175 6.22525L7.98132 5.06567L12.9157 10L7.98132 14.9344L6.82175 13.7748Z" fill="#7E8299" />
                      </svg>
                      <span className='pt_color'>{project?.title}</span>
                    </div></NavLink>
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="catalog_details">
                      <div className="row">
                        {data4?.img?.map((e, index) => {
                          return (
                            <div key={index} className="col-md-3 cata_lok">
                              <div className="catalog_box_img">
                                <img width="269" height="151" src={e.url} alt="" />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="row mt-10">
                        <div className="col-md-11 detr_sus">
                          <div className="detr">
                            <h3>{data4?.title}</h3>
                            <p>{data4?.desc?.simpleText}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h4>Select a combination</h4> */}

                    {/* <div className="tab-content d-block" id="myTabContent">
                      <div className="tab-pane d-block fade show" id='kt_customer_view_overview_activity_tab' role="tabpanel" aria-labelledby="#serf1">
                        <div className="parents_component">
                          {components.map((e, index) => {
                            if (Object.keys(toggle).includes(e._id) && toggle[e._id].length > 0) {
                              return (
                                <React.Fragment key={index}>
                                  <div onClick={() => {
                                    handleOpenChild(e._id);
                                  }} className="parent_component pc cursor-pointer" id={`c-${e._id}`}>
                                    <div className="pc1">
                                      <p>{e.title}</p>
                                    </div>
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </div>
                                  </div>

                                  <div id={`scp-${e._id}`} style={{ display: 'flex', alignItems: 'flex-end', width: '100%', flexDirection: 'column' }}>
                                    {subComponent[e._id] && subComponent[e._id]?.length > 0 && subComponent[e._id].map((f, index1) => {
                                      if (toggle[e._id].includes(f._id)) {
                                        return (
                                          <div key={index1} className="child_component pc" id={`sc-${f._id}`}>
                                            <div className="pc1">
                                              <div className="form-check form-switch form-check-custom form-check-solid">
                                                <input onChange={() => {
                                                  handleCheckBox(`${f.title}`, index);
                                                  handleCheckBox1(f._id, e._id);
                                                }} className="form-check-input" type="radio" name={`togg${e._id}`} value="" id={`s-${f._id}`} defaultChecked={files3[e._id]?.includes(f._id)} />
                                              </div>
                                              <p className='subComponent'>{f.title}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div> */}

                    {!loadFlag ? <div className="youTube_counter mt-8">
                      <div className="counter_header">
                        <h1>{project?.title}</h1>
                      </div>

                      {/* <input className='form-control conts' type="text" placeholder='Enter Project Name' name="projectName" onChange={(e) => setProjectName(e.target.value)} value={projectName} /> */}

                      {/* <div className="counter_form">
                        <ReactFormGenerator
                          onSubmit={handleSubmit}
                          form_method="POST"
                          answer_data={answerData}
                          data={data}
                        />
                      </div>
                    </div> : <div className="spinner-grow  d-block m-auto text-primary mt-4" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>} */}

                      {/* Youtube rapid api */}
                      {data3?.youtubeFlag === "true" && <form onSubmit={(e) => {
                        e.preventDefault();
                        handleChannel();
                      }} className='rapid_fetch'>
                        <span>Youtube Channel</span>
                        <input name='channelName' type="text" onChange={(e) => { setChannelName({ ytChannelUrl: e.target.value }); }} value={channelName.ytChannelUrl} defaultValue={"https://youtube.com/@HackMakeMod?si=e1kN7nteNqtQSCSP"} required />

                        {/* <button type='submit' disabled={isDisabled}>{flag1 ? 'Loading ...' : "Check It!"}</button> */}
                        <button type='submit' disabled={isDisabled}>{flag1 ? 'Loading ...' : "Check It!"}</button>

                        {data2 && <div className='rapid_div'>
                          <div>
                            <img className='rapid_img' src={data2?.authorThumbnails?.[2]?.url} alt="" />
                          </div>
                          <div className='testing_rapid'>
                            <h4>{data2?.channel}</h4>
                            <p> <span>{data2?.subscribers}</span> Subs</p>
                            <p> <span>{data2?.views}</span> Views</p>
                          </div>
                        </div>}
                      </form>}

                      <div className="counter_form">
                        {(data2 || data3?.youtubeFlag === "false") && <ReactFormGenerator
                          onSubmit={handleSubmit}
                          actionName={"Save"}
                          // form_action={(e) => { e.preventDefault(); }}
                          form_method="POST"
                          answer_data={answerData}
                          data={data}
                        />}
                      </div>
                    </div> : <div className="spinner-grow  d-block m-auto text-primary mt-4" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>}

                    <button
                      type="button"
                      id="down-btn"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      style={{ visibility: 'hidden' }}
                    >
                      Download
                    </button>

                    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                      <div className="modal-dialog">
                        <div className="modal-content modal_content1">
                          <div className="modal-header modal-header1">

                            {/*begin::Close*/}
                            <div
                              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="42" height="42" rx="21" fill="#F3F6F8" />
                                <path d="M26.8332 16.3415L25.6582 15.1665L20.9998 19.8248L16.3415 15.1665L15.1665 16.3415L19.8248 20.9998L15.1665 25.6582L16.3415 26.8332L20.9998 22.1748L25.6582 26.8332L26.8332 25.6582L22.1748 20.9998L26.8332 16.3415Z" fill="#5E6278" />
                              </svg>

                            </div>
                            {/*end::Close*/}
                          </div>

                          <div className="modal_download">
                            <h2 className='text-center'>Download Your Projects</h2>
                          </div>

                          <div className="modal-body">
                            <div className='modal_files'>
                              <div className="modal_file">
                                <span>Wire_Diagram.pdf</span>
                                <button onClick={() => {
                                  downloadURI(userBuilder?.wireFile?.url, 'wirefile');
                                }}>
                                  <span>Download File</span>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                                  </svg>
                                </button>
                              </div>

                              <div className="modal_file">
                                <span>Upload Enclosure</span>
                                <button onClick={() => {
                                  downloadURI(userBuilder?.enclosureFile?.url, 'enclosure');
                                }}>
                                  <span>Download File</span>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                                  </svg>
                                </button>
                              </div>

                              <div className="modal_file">
                                <span>Upload Code Template</span>
                                <button onClick={() => {
                                  // downloadURI(userBuilder?.codeFile?.url, 'codefile');
                                  downloadAll(true);
                                }}>
                                  <span>Download File</span>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="modal-footer modal-footer1">
                            <button onClick={() => {
                              downloadAll(false);
                            }} className="download_all">
                              <span>Download All File</span>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5 9.875V14.25C15.5 14.5815 15.3683 14.8995 15.1339 15.1339C14.8995 15.3683 14.5815 15.5 14.25 15.5H1.75C1.41848 15.5 1.10054 15.3683 0.866116 15.1339C0.631696 14.8995 0.5 14.5815 0.5 14.25V9.875C0.5 9.70924 0.565848 9.55027 0.683058 9.43306C0.800269 9.31585 0.95924 9.25 1.125 9.25C1.29076 9.25 1.44973 9.31585 1.56694 9.43306C1.68415 9.55027 1.75 9.70924 1.75 9.875V14.25H14.25V9.875C14.25 9.70924 14.3158 9.55027 14.4331 9.43306C14.5503 9.31585 14.7092 9.25 14.875 9.25C15.0408 9.25 15.1997 9.31585 15.3169 9.43306C15.4342 9.55027 15.5 9.70924 15.5 9.875ZM7.55781 10.3172C7.61586 10.3753 7.68479 10.4214 7.76066 10.4529C7.83654 10.4843 7.91787 10.5005 8 10.5005C8.08213 10.5005 8.16346 10.4843 8.23934 10.4529C8.31521 10.4214 8.38414 10.3753 8.44219 10.3172L11.5672 7.19219C11.6253 7.13412 11.6713 7.06518 11.7027 6.98931C11.7342 6.91344 11.7503 6.83212 11.7503 6.75C11.7503 6.66788 11.7342 6.58656 11.7027 6.51069C11.6713 6.43482 11.6253 6.36588 11.5672 6.30781C11.5091 6.24974 11.4402 6.20368 11.3643 6.17225C11.2884 6.14083 11.2071 6.12465 11.125 6.12465C11.0429 6.12465 10.9616 6.14083 10.8857 6.17225C10.8098 6.20368 10.7409 6.24974 10.6828 6.30781L8.625 8.36641V1.125C8.625 0.95924 8.55915 0.800269 8.44194 0.683058C8.32473 0.565848 8.16576 0.5 8 0.5C7.83424 0.5 7.67527 0.565848 7.55806 0.683058C7.44085 0.800269 7.375 0.95924 7.375 1.125V8.36641L5.31719 6.30781C5.19991 6.19054 5.04085 6.12465 4.875 6.12465C4.70915 6.12465 4.55009 6.19054 4.43281 6.30781C4.31554 6.42509 4.24965 6.58415 4.24965 6.75C4.24965 6.91585 4.31554 7.07491 4.43281 7.19219L7.55781 10.3172Z" fill="white" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* =============dolibo live code and plan file popup code========== */}
                    {/* {console.log(userBuilder)} */}

                    {userBuilder && Object.keys(userBuilder).length > 0 && <div className="dolibo_copoy">
                      <div id="code-m" data-bs-toggle="modal" data-bs-target="#kt_modal_2" className="dolibo_button cursor-pointer">
                        <div className="css_trigt">
                          <img src={doliboShort} alt="" />
                        </div>
                        <p>Code File!</p>
                      </div>

                      <div data-bs-toggle="modal"
                        data-bs-target="#kt_modal_3" className="dolibo_button1 cursor-pointer">
                        <div className="css_trigt">
                          <img src={doliboShort} alt="" />
                        </div>
                        <p>Plan File!</p>
                      </div>

                      <div data-bs-toggle="modal"
                        data-bs-target="#kt_modal_4" className="dolibo_button1 cursor-pointer">
                        <div className="css_trigt ">
                          <img src={doliboShort} alt="" />
                        </div>
                        <p>3D Enclosure!</p>
                      </div>

                      {/* for code file modal  */}
                      <div className="modal fade" tabIndex={-1} id="kt_modal_2">
                        <div className="modal-dialog code_dialog">
                          <div className="modal-content code_modal">
                            <div className="modal-header code_header">
                              <h3 className="modal-title code_title11">YOUTUBE COUNTER AND CLOCK</h3>
                              {/*begin::Close*/}
                              <div className="button_clipboard">
                                <button onClick={() => {
                                  navigator.clipboard.writeText(code);
                                  // window.alert("copy to clipboard");
                                  notify("success", "copy to clipboard");
                                }}>Copy To Clipboard</button>
                              </div>
                              <div className="btn_dwnl">
                                <button
                                  onClick={() => {
                                    // downloadURI(userBuilder?.codeFile?.url, 'codefile');
                                    downloadAll(true);
                                  }}
                                >Download</button>
                              </div>
                              <div
                                className="btn btn-icon btn-sm ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                {/* <i className="ki-duotone ki-cross fs-1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i> */}
                                <svg className='svg_teen' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="3rem" width="3rem" xmlns="http://www.w3.org/2000/svg"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                              </div>
                              {/*end::Close*/}
                            </div>
                            <div className="modal-body modal-body_code">
                              {/* {Prism.highlight(code, Prism.languages.javascript, 'javascript')} */}
                              <Highlight
                                theme={themes.shadesOfPurple}
                                code={code}
                                language="tsx"
                              >
                                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                                  <pre className='pre_ren' style={style}>
                                    {tokens.map((line, i) => (
                                      <div key={i} {...getLineProps({ line })}>
                                        {/* <span>{i + 1}</span> */}
                                        {line.map((token, key) => (
                                          <span key={key} {...getTokenProps({ token })} />
                                        ))}
                                      </div>
                                    ))}
                                  </pre>
                                )}
                              </Highlight>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* code file modal end */}

                      {/* ==========for wire diagram modal===== */}
                      <div className="modal fade" tabIndex={-1} id="kt_modal_3">
                        <div className="modal-dialog wire_dialog">
                          <div className="modal-content wire_content">
                            <div className="modal-header wire_header">
                              <h3 className="modal-title wire_title">Youtube Counter Diagram</h3>
                              {/*begin::Close*/}
                              <div className="btn_dwnl">
                                <button onClick={() => {
                                  downloadURI(userBuilder?.wireFile?.url, 'wirefile');
                                }}>Download</button>
                              </div>
                              <div
                                className="btn btn-icon btn-sm  ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <svg className='svg_teen' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="3rem" width="3rem" xmlns="http://www.w3.org/2000/svg"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                              </div>
                              {/*end::Close*/}
                            </div>
                            <div className="modal-body diagram_body">
                              <img src={userBuilder?.wireFile?.url} alt="capture" />
                            </div>
                            {/* <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" className="btn btn-primary">
                                Save changes
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* =============for wire diagram modal end======= */}

                      {/* Enclosure File */}
                      <div className="modal fade" tabIndex={-1} id="kt_modal_4">
                        <div className="modal-dialog wire_dialog">
                          <div className="modal-content wire_content">
                            <div className="modal-header wire_header">
                              <h3 className="modal-title wire_title">Youtube Counter Diagram</h3>
                              {/*begin::Close*/}
                              <div className="btn_dwnl">
                                <button onClick={() => {
                                  downloadURI(userBuilder?.enclosureFile?.url, 'enclosure');
                                }}>Download</button>
                              </div>
                              <div
                                className="btn btn-icon btn-sm  ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <svg className='svg_teen' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="3rem" width="3rem" xmlns="http://www.w3.org/2000/svg"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                              </div>
                              {/*end::Close*/}
                            </div>
                            <div className="modal-body diagram_body">
                              <StlViewer
                                style={style}
                                orbitControls
                                shadows
                                url={userBuilder?.enclosureFile?.url}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <StlViewer
                        style={style}
                        orbitControls
                        shadows
                        url={userBuilder?.enclosureFile?.url}
                      /> */}
                      {/* Enclosure File */}
                    </div>}
                    {/* =====================dolibo live code and plan file popup end========= */}
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default BuildProject1;
