import React from 'react'
// import UserNavbar from '../../EndUser/UserNavbar/UserNavbar'
import dolo from '../../../images/dolo.png';
import { NavLink } from 'react-router-dom';
import converter from '../../../images/converter.png';
const UserInformative = () => {
    return (
        <>
            {/* <div className="userProfile_navbar">
         <nav className='end_nav'>
            
         </nav>
     </div> */}

            <div className="home-navbar">
                <nav className="t_navbar">
                   <NavLink to="/"><div className="nav_logo">
                        <img src={dolo} alt="" />
                    </div></NavLink>
                    <div className="reg_page">
                        <div className="user_end_btn">
                           <NavLink to="/endUser/dashboard"><button>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.33997 0.5C9.40083 0.5 10.4182 0.921427 11.1684 1.67157C11.9185 2.42172 12.34 3.43913 12.34 4.5C12.34 5.56087 11.9185 6.57828 11.1684 7.32843C10.4182 8.07857 9.40083 8.5 8.33997 8.5C7.2791 8.5 6.26168 8.07857 5.51154 7.32843C4.76139 6.57828 4.33997 5.56087 4.33997 4.5C4.33997 3.43913 4.76139 2.42172 5.51154 1.67157C6.26168 0.921427 7.2791 0.5 8.33997 0.5ZM8.33997 2.5C7.80953 2.5 7.30083 2.71071 6.92575 3.08579C6.55068 3.46086 6.33997 3.96957 6.33997 4.5C6.33997 5.03043 6.55068 5.53914 6.92575 5.91421C7.30083 6.28929 7.80953 6.5 8.33997 6.5C8.8704 6.5 9.37911 6.28929 9.75418 5.91421C10.1293 5.53914 10.34 5.03043 10.34 4.5C10.34 3.96957 10.1293 3.46086 9.75418 3.08579C9.37911 2.71071 8.8704 2.5 8.33997 2.5ZM8.33997 9.5C11.01 9.5 16.34 10.83 16.34 13.5V16.5H0.339966V13.5C0.339966 10.83 5.66997 9.5 8.33997 9.5ZM8.33997 11.4C5.36997 11.4 2.23997 12.86 2.23997 13.5V14.6H14.44V13.5C14.44 12.86 11.31 11.4 8.33997 11.4Z" fill="#3595F6" />
                                </svg>
                                <span>Profile</span>
                            </button></NavLink>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="home-main">
                <div className="home_banner">
                    <div className="home_banner_left">
                        <div className="home_head">
                            <h1>
                                Empower Your Projects with Effortless Code Generation
                            </h1>
                            {/* <h1>
                                Projects with
                            </h1>
                            <h1>Effortless Code</h1>
                            <h1>Generation</h1> */}
                        </div>
                        <div className="head_btn head_btn1">
                       <NavLink to="/endUser/dashboard"><button>GET STARTED WITH DOLIBO</button></NavLink>
                        </div>
                    </div>
                    <div className="home_banner_right">
                        <div className="img_banner">
                            <img src={converter} alt="converter" />
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="dolibo_welcome">
                <div className="dolibo_welcome_page">
                     <div className="welcome_heading">
                          <h2>Welcome to <span>DoLibo!</span> </h2>
                          <p>We are working hard with our partners to come up with new and interesting projects for the "DIY-er" and "Tinkerer" that use social media, US Census, Weather, Aviation and many other interesting data points. Our goal is to expose them through an easy to use API. We will continue to find new and exciting projects that utilize mainstream micro controllers and micro computers like Arduino based devices and Unix driven micro-computers like the Raspberry Pi.</p>
                     </div>
                   
                </div>
            </div>

        </>
    )
}

export default UserInformative