import React, { useState, useEffect } from 'react';
import UserNavbar from '../UserNavbar/UserNavbar';
import UserSidebar from '../UserSidebar/UserSidebar1';
import { useMain } from '../../../hooks/useMain';
import { useNavigate } from 'react-router-dom';
import type2 from '../../../images/type2.png';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
var quillObj1;

var toolbarOptions = {
  container: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    ['link'],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    ['clean']
  ]
};

const EditProfile = ({ notify }) => {
  const navigate = useNavigate();
  const { updateUser } = useMain();
  const [value, setValue] = useState({});
  const [image, setImage] = useState(null)

  let user = JSON.parse(localStorage.getItem('dolibo_user'));

  // const [bio, setBio] = useState({
  //   richText: '',
  //   simpleText: '',
  //   textLength: 0
  // });


  useEffect(() => {
    let user1 = JSON.parse(localStorage.getItem("dolibo_user"));
    setValue({ ...user1, password: '' });
  }, [localStorage.getItem("dolibo_user")]);

  const handleChange = (e) => {
    if (e.target.name === "file" && e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        localStorage.setItem("img", setImage(e.target.result));
      };
      reader.readAsDataURL(e.target.files[0]);
      setValue({ ...value, [e.target.name]: e.target.files[0] });
    }
    else {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  // const rteChange1 = (content, delta, source, editor) => {

  //   setBio({
  //     richText: editor.getHTML(),
  //     simpleText: editor.getText(),
  //     textLength: editor.getLength()
  //   })
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(value);

    if (!value.password) {
      value.password = '';
    }

    if (!value.password1) {
      value.password1 = '';
    }
    if (value.password !== value.password1) {
      notify(false, "Password and confirm password must be same");
      return;
    }

    const ans = await updateUser({ ...value, userId: value._id });
    console.log(ans);
    localStorage.setItem('dolibo_user', JSON.stringify(ans.data));

    if (ans.status) {
      notify(ans.status, ans.message);
      navigate("/endUser/myProfile");
    }
    else {
      console.log("error");
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

          <UserNavbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <UserSidebar />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="user_profil user_profiling">
                      <div className="header_profile">
                        <h2>My Profile</h2>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="person_information">
                          <div className='peru'>
                            <h2>Personal Information</h2>
                          </div>
                          <div className="edit_user_form mt-7">
                            <div className="form_inj">
                              <div className=' d-flex align-items-center'>
                                <div className="end_input">
                                  <label htmlFor="file">Update Profile</label>
                                  <input
                                    className='form-control tang'
                                    type="file"
                                    name='file'
                                    onChange={handleChange}
                                    id='file'
                                  />
                                  {/* { image && <img style={{objectFit:"cover"}} className='uing' width="100px" height="100px" src={image} /> }    */}
                                </div>
                                {
                                  image ? <img style={{ objectFit: "cover", marginLeft: "15px", marginTop: "24px" }} width="50px" height="50px" src={image} alt="" /> : <img style={{ marginLeft: "15px", marginTop: "24px" }} className={`${user?.img?.url} && uuii `} src={user?.img?.url ? user?.img?.url : type2} alt="labelInm" />
                                }
                              </div>

                              <div className="end_input">
                                <label htmlFor="name">Name</label>
                                <input
                                  placeholder='Your Name'
                                  className='form-control'
                                  type="text"
                                  name='name'
                                  value={value.name}
                                  onChange={handleChange}
                                  id='name'
                                />
                              </div>

                              <div className="end_input">
                                <label htmlFor="email">Email Address</label>
                                <input
                                  placeholder='Your Email'
                                  className='form-control'
                                  type="email"
                                  name='email'
                                  value={value.email}
                                  onChange={handleChange}
                                  id='email'
                                />
                              </div>

                              <div className="end_input">
                                <label htmlFor="bio">Bio</label>
                                {/* <input
                                  placeholder='Text Here'
                                  className='form-control'
                                  type="text"
                                  name='bio'
                                  value={value.bio}
                                  onChange={handleChange}
                                  id='bio'
                                /> */}
                                <textarea
                                  name="bio"
                                  id="bio"
                                  value={value.bio}
                                  onChange={handleChange}
                                  className='form-control'
                                  cols="30"
                                  rows="10"
                                  placeholder='Text Here'
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="address">
                          <div className='peru'>
                            <h2>Address</h2>
                          </div>
                          <div className="edit_user_form mt-7">
                            <div className="form_inj">
                              <div className="end_input">
                                <label htmlFor="country">Country</label>
                                <input
                                  placeholder='Your Country'
                                  className='form-control'
                                  type="text"
                                  id='country'
                                  name='country'
                                  value={value.country}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="end_input">
                                <label htmlFor="state">City/State</label>
                                <input
                                  placeholder='Your State'
                                  className='form-control'
                                  type="text"
                                  name='state'
                                  value={value.state}
                                  onChange={handleChange}
                                  id='state'
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="address">
                          <div className='peru'>
                            <h2>Manage Password</h2>
                          </div>
                          <div className="edit_user_form mt-7">
                            <div className="form_inj">
                              <div className="end_input">
                                <label htmlFor="password">New Password</label>
                                <input
                                  placeholder='Generate new password'
                                  className='form-control'
                                  type="password"
                                  id='password'
                                  name='password'
                                  value={value?.password}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="end_input">
                                <label htmlFor="password1">Confirm Password</label>
                                <input
                                  placeholder='Re-enter password'
                                  className='form-control'
                                  type="password"
                                  name='password1'
                                  value={value?.password1}
                                  onChange={handleChange}
                                  id='password1'
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="update_end_profile">
                          <button type="submit" class="btn btn-primary">Update Profile</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default EditProfile;
