import React from 'react';
// import treeing from '../../images/treeing.png';
// import counter1 from '../../images/counter1.png';
// import counter2 from '../../images/counter2.png';
// import convent from '../../images/convent.png';
// import convent1 from '../../images/convent1.png';
import footerLogo from '../../images/footer_logo.png';
import vector1 from '../../images/Vector (1).png';
import vector2 from '../../images/Vector (2).png';
import vector3 from '../../images/Vector (3).png';
import vector4 from '../../images/Vector (4).png';
import converter from '../../images/converter.png';
import HomeNavbar from './HomeNavbar';
import wifi from '../../images/wifi.png';
import { NavLink } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

const Home = () => {
    //   const navigate = useNavigate();

    // useEffect(() => {
    //   navigate("/login");
    // }, []);

    return (
        <>
            {/* <div className='mt-6'>
        <h1 className='text-center'>Welcome to Dolibo</h1>
        <div className="btn-group d-flex align-items-center justify-content-center">
            <NavLink to="/endUser/register">EndUser Registration</NavLink>
            <NavLink to="/login">Go To Login</NavLink>
        </div>
    </div> */}

            <HomeNavbar />

            <div className="home-main">
                <div className="home_banner">
                    <div className="home_banner_left">
                        <div className="home_head">
                            <h1>
                                {/* Empower Your Projects with Effortless Code Generation */}
                                Microcontroller projects made <br /> !EASY
                            </h1>
                            {/* <h1>
                                Projects with
                            </h1>
                            <h1>Effortless Code</h1>
                            <h1>Generation</h1> */}
                        </div>
                        {/* <div className="head_btn">
                        <button>GET STARTED WITH DOLIBO</button>
                        </div> */}
                    </div>
                    <div className="home_banner_right">
                        <div className="img_banner">
                            {/* <img src={converter} alt="treeing" /> */}
                            <img src={wifi} alt="" />
                        </div>
                    </div>
                </div>
                <div className="head_btn">
                    <NavLink to="/signin"><button className='gt_st'>GET STARTED WITH DOLIBO</button></NavLink>
                </div>
            </div>

            {/* <div className="dolibo_welcome">
                <div className="dolibo_welcome_page">
                     <div className="welcome_heading">
                          <h2>Welcome to <span>DoLibo!</span> </h2>
                          <p>We are working hard with our partners to come up with new and interesting projects for the "DIY-er" and "Tinkerer" that use social media, US Census, Weather, Aviation and many other interesting data points. Our goal is to expose them through an easy to use API. We will continue to find new and exciting projects that utilize mainstream micro controllers and micro computers like Arduino based devices and Unix driven micro-computers like the Raspberry Pi.</p>
                     </div>
                     <div className="welcone_card">
                         <div className="welcone_box">
                              <div className="counter_img">
                                  <img src={counter1} alt="" />
                              </div>
                              <div className="box_paras">
                                  <h3>YouTube Counter and Clock</h3>
                                  <p>Use a microprocessor and display to show real-time youtube stats including channel name, subscribers, views, time and date.</p>
                              </div>
                              <div className="box_btn">
                                  <button>Build This Project</button>
                              </div>
                         </div>
                         <div className="welcone_box welcone_box1">
                              <div className="counter_img">
                                  <img src={counter2} alt="" />
                              </div>
                              <div className="box_paras">
                                  <h3>Unit Converter</h3>
                                  <p>Create a handy device that will convert measurements from one standard to another.</p>
                              </div>
                              <div className="box_btn">
                                  <button>Build This Project</button>
                              </div>
                         </div>
                     </div>
                     <div className="welcone_card_btn">
                         <button>View All Projects</button>
                     </div>
                </div>
            </div>

            <div className="acclearte">
                <div className="accelarate_page">
                      <h3> <span>Accelerate high-quality software development.</span>  Our easy to use system will dynamically generate the code you need for your customized project.</h3>

                      <div className="convent_code">
                          <img src={convent} alt="" />
                      </div>
                </div>
            </div>

            <div className="acclearte accl1">
                <div className="accelarate_page">
                      <h3> <span> Wire up your projects with ease.</span> Our simple to understand wiring diagrams will help you get your projects done right the first time.</h3>

                      <div className="convent_code">
                          <img src={convent1} alt="" />
                      </div>
                </div>
            </div> */}

            <div className="footer">
                <footer className='fite'>
                    <div className="footer_logo">
                        <img src={footerLogo} alt="" />
                    </div>
                    <div className="footer_redirect">
                        <p>HackMakeMod Inc.</p>
                    </div>
                    <div className="footer_menu ">
                        <div className='footer_link'>
                            <p>About Us</p>
                            <p>Contact Us</p>
                        </div>
                        <div className="footer_social_link">
                            <img src={vector1} alt="" />
                            <img src={vector2} alt="" />
                            <img src={vector3} alt="" />
                            <img src={vector4} alt="" />
                        </div>
                    </div>

                    <div className="hr">
                        <hr />
                        <p>© {new Date().getFullYear()} Your dolibo. All rights reserved.</p>
                    </div>
                </footer>
            </div>

        </>
    )
}

export default Home;
