import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar';
import login1 from '../../../images/logoLogin.png'
import asin from '../../../images/asin.png';
import Sidebar from '../sidebar/Sidebar';
import { useMain } from '../../../hooks/useMain';

const Dashboard = () => {
  const { getUsers, getProjects, getUserBuilders } = useMain();

  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [userProject,setUserProject] = useState([]);
  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const ans = await getUsers('','','','');
    const ans1 = await getProjects('','','','');
    const ans2 = await getUserBuilders('','','','');
    // console.log(ans);
    setUsers(ans.data);
    setProjects(ans1.data);
    setUserProject(ans2.data);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <Navbar />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <Sidebar />



            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                      <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Dolibo Dashboard
                      </h1>
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                          <a
                            href="../../demo1/dist/index.html"
                            className="text-muted text-hover-primary"
                          >
                            Home
                          </a>
                        </li>
                        <li className="breadcrumb-item">
                          <span className="bullet bg-gray-400 w-5px h-2px" />
                        </li>
                        <li className="breadcrumb-item text-muted">Dashboards</li>
                      </ul>
                    </div>
                    {/* <div className="d-flex align-items-center gap-2 gap-lg-3">
                      <a
                        href="#"
                        className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                      >
                        Manage Sales
                      </a>
                      <a href="#" className="btn btn-sm fw-bold btn-primary">
                        Add Product
                      </a>
                    </div> */}
                  </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="row g-5 g-xl-10 mb-xl-10">
                      <div className="col-md-4">
                        <div className="card  card-flush card-flush1">
                          <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                              <div className="d-flex align-items-center">
                                {/* <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                                  $
                                </span> */}
                                <span className=" fst fw-bold text-dark me-2 lh-1 ls-n2">
                                  Users
                                </span>
                              </div>
                              <span className="text-gray-400 pt-1 mt-2 fw-semibold fs-6">
                                {users?.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card card-flush card-flush1">
                          <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                              <div className="d-flex align-items-center">
                                <span className=" fst fw-bold text-dark me-2 lh-1 ls-n2">
                                  Projects
                                </span>
                              </div>
                              <span className="text-gray-400 pt-1 mt-2 fw-semibold fs-6">
                                {projects?.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card card-flush card-flush1">
                          <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                              <div className="d-flex align-items-center">
                                <span className=" fst fw-bold text-dark me-2 lh-1 ls-n2">
                                  User Projects
                                </span>
                                {/* <span className="badge badge-light-danger fs-base">
                                  <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                  2.2%
                                </span> */}
                              </div>
                              <span className="text-gray-400 pt-1 mt-2 fw-semibold fs-6">
                                {userProject?.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div>
                  <div className="accordion" id="kt_accordion_1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="kt_accordion_1_header_1">
                        <button className="accordion-button fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">
                          Accordion Item #1
                        </button>
                      </h2>
                      <div id="kt_accordion_1_body_1" className="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                        <div className="accordion-body">
                          ...
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="kt_accordion_1_header_2">
                        <button className="accordion-button fs-4 fw-semibold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_2" aria-expanded="false" aria-controls="kt_accordion_1_body_2">
                          Accordion Item #2
                        </button>
                      </h2>
                      <div id="kt_accordion_1_body_2" className="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_2" data-bs-parent="#kt_accordion_1">
                        <div className="accordion-body">
                          ...
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="kt_accordion_1_header_3">
                        <button className="accordion-button fs-4 fw-semibold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_3" aria-expanded="false" aria-controls="kt_accordion_1_body_3">
                          Accordion Item #3
                        </button>
                      </h2>
                      <div id="kt_accordion_1_body_3" className="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_3" data-bs-parent="#kt_accordion_1">
                        <div className="accordion-body">
                          ...
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              </div>
              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default Dashboard;
