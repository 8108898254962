import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMain } from '../../../hooks/useMain';
import OutsideClickHandler from 'react-outside-click-handler';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from '../../../Utils/Loader';

const ShowProject = ({ notify }) => {
  let { getProjects, deleteProject, updateProjectStatus } = useMain();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [value, setValue] = useState({
    query: ""
  });
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [loadFlag, setLoadFlag] = useState(false);
  const [loadFlag1, setLoadFlag1] = useState(true);
  const [updateNum, setUpdateNum] = useState(-1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData();
  }, [refreshFlag, page, perPage]);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData();
  };

  const getData = async () => {
    setLoadFlag1(true);
    const ans = await getProjects('', value.query, page, perPage);
    console.log(ans);
    setData(ans.data);
    setTotal(ans.count);
    setLoadFlag1(false);
  };

  const deleteProject1 = async (id) => {
    confirmAlert({
      title: 'Are you sure to delete this data?',
      message: 'All related data to this will be deleted',
      buttons: [
        {
          label: 'Yes, Go Ahead!',
          style: {
            background: "#FF5449"
          },
          onClick: async () => {
            const ans = await deleteProject(id);
            console.log(ans);
            if (ans.status) {
              // alert(ans.message);
              notify(ans.status, ans.message);
              setRefreshFlag(!refreshFlag);
            }
            else {
              alert('Something went wrong! ');
            }
          }
        },
        {
          label: 'Cancel',

          onClick: () => null
        }
      ]
    });
  };

  const updateStatus = async (id, status, index) => {
    setUpdateNum(index);
    setLoadFlag(true);
    const ans = await updateProjectStatus({ id, status });
    notify(ans.status, ans.message);
    if (ans.status) {
      setRefreshFlag(!refreshFlag);
    }
    // setUpdateNum(-1);
    // setLoadFlag(false);
    setTimeout(() => {
      setUpdateNum(-1);
      setLoadFlag(false);
    }, 400);
  };

  return (
    <>
      {loadFlag1 && <Loader />}
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <Navbar />

          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >

            <Sidebar />

            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                      <h1 className="page-heading page-heading1 d-flex   flex-column justify-content-center my-0">
                        Projects
                      </h1>

                    </div>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                      <NavLink to="/admin/createProject" className="btn btn-thin btn-sm  btn-primary">
                        Create New Project
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="card card-flush card-body2 card-slush">
                      {/*begin::Card header*/}
                      <div className="card-header pt-8">
                        <div className="card-title block card-title1">
                          {/*begin::Search*/}
                          <div className="active_project">
                            <h2>Active Projects</h2>
                            <form onKeyUp={handleSearch} className="form_search">
                              <input type="search" onChange={handleChange} name='query' value={value.query} className='form-control' placeholder='search' />
                            </form>
                          </div>
                          {/*end::Search*/}
                        </div>
                      </div>

                      <div className="card-body card-body4 card-si table-responsive">
                        <table
                          id="kt_file_manager_list"
                          data-kt-filemanager-table="files"
                          className="table align-middle table-row-dashed fs-6 gy-5"
                        >
                          <thead className="suching">
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 tr-fash">
                              <th className="name44">Project name</th>
                              <th className="name44">Created By</th>
                              <th className="name44">Creation Date</th>
                              <th className="name44">Status</th>
                              <th className="name44">Action</th>
                            </tr>
                          </thead>

                          <tbody className="fw-semibold text-gray-600">
                            {data.map((e, index) => {
                              return (
                                <tr key={index} className="tri11">
                                  <td>
                                    <NavLink style={{ color: 'black' }} to={`/admin/updateProject/${e._id}`}>
                                      {e.title}
                                    </NavLink>
                                  </td>
                                  <td>{e.createdBy.name}</td>
                                  <td>
                                    {new Date(Number(e.ts)).toLocaleDateString()}
                                  </td>

                                  <td className='cursor-pointer' >
                                    {/* <div onClick={() => {
                                    updateStatus(e._id, e.status === "true" ? "false" : "true", index);
                                  }} className="status-baring">
                                      {loadFlag && index === updateNum ? <p style={{ fontSize: "13px" }}>Updating ..</p> : <>
                                        <div className={e.status === "false" ? "reding" : "green"} />
                                        <p>{e.status === "false" ? "inactive" : "active"}</p>
                                      </>}

                                      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.06 0.529999L4 3.58333L0.94 0.529999L0 1.47L4 5.47L8 1.47L7.06 0.529999Z" fill="#7E8299" />
                                      </svg>
                                    </div> */}

                                    <OutsideClickHandler
                                      onOutsideClick={() => {
                                        if (!document.getElementById(`action_boxsss${index}`).classList.contains('d-none')) {
                                          document.getElementById(`action_boxsss${index}`).classList.add('d-none');
                                        }
                                      }}
                                    >
                                      <div className="dropdown">
                                        <div onClick={() => {
                                          // updateStatus(e._id, e.status === "true" ? "false" : "true", index);
                                          document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                        }} className="status-baring">
                                          {loadFlag && index === updateNum ? <p style={{ fontSize: "13px" }}>Updating ..</p> : <>
                                            <div className={e.status === "false" ? "reding" : "green"} />
                                            <p>{e.status === "false" ? "inactive" : "active"}</p>
                                          </>}

                                          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.06 0.529999L4 3.58333L0.94 0.529999L0 1.47L4 5.47L8 1.47L7.06 0.529999Z" fill="#7E8299" />
                                          </svg>
                                        </div>

                                        <ul id={`action_boxsss${index}`} data-popper-placement="left-start" className=" d-none action_boxsss  dropdowns_menu dt_menu">
                                          <div className='ting' onClick={() => {
                                            updateStatus(e._id, "true", index);
                                            document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                          }}>
                                            <div className="act"></div>
                                            <span>active</span>
                                          </div>
                                          <div className='mt-2 ting' onClick={() => {
                                            updateStatus(e._id, "false", index);
                                            document.getElementById(`action_boxsss${index}`).classList.toggle('d-none');
                                          }}>
                                            <div className='inact'></div>
                                            <span>Inactive</span>
                                          </div>
                                        </ul>
                                      </div>
                                    </OutsideClickHandler>
                                  </td>

                                  <td>
                                    <OutsideClickHandler
                                      onOutsideClick={() => {
                                        if (!document.getElementById(`action_box${index}`).classList.contains('d-none')) {
                                          document.getElementById(`action_box${index}`).classList.add('d-none');
                                        }
                                      }}
                                    >
                                      <svg className='cursor-pointer' onClick={() => {
                                        document.getElementById(`action_box${index}`).classList.toggle('d-none');
                                      }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="15" cy="15" r="14.5" fill="white" stroke="#E1E3EA" />
                                        <path d="M11 13.6667C10.2667 13.6667 9.66667 14.2667 9.66667 15C9.66667 15.7333 10.2667 16.3333 11 16.3333C11.7333 16.3333 12.3333 15.7333 12.3333 15C12.3333 14.2667 11.7333 13.6667 11 13.6667ZM19 13.6667C18.2667 13.6667 17.6667 14.2667 17.6667 15C17.6667 15.7333 18.2667 16.3333 19 16.3333C19.7333 16.3333 20.3333 15.7333 20.3333 15C20.3333 14.2667 19.7333 13.6667 19 13.6667ZM15 13.6667C14.2667 13.6667 13.6667 14.2667 13.6667 15C13.6667 15.7333 14.2667 16.3333 15 16.3333C15.7333 16.3333 16.3333 15.7333 16.3333 15C16.3333 14.2667 15.7333 13.6667 15 13.6667Z" fill="#5E6278" />
                                      </svg>

                                      <div id={`action_box${index}`} className='d-none action_box'>
                                        <div onClick={() => {
                                          navigate(`/admin/updateProject/${e._id}`);
                                        }}>
                                          edit
                                        </div>
                                        <div onClick={() => {
                                          deleteProject1(e._id);
                                        }}>
                                          delete
                                        </div>
                                      </div>
                                    </OutsideClickHandler>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div style={{ width: 'fit-content', margin: 'auto' }} className="view_all">
                          <button disabled={page === 1} className='btn btn-primary btn1' onClick={() => {
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}>Previous</button>
                          <span className='btn2'>Page {page}</span>
                          <button disabled={(page * perPage) >= total} className='btn btn-primary btn3' onClick={() => {
                            if ((page * perPage) < total) {
                              setPage(page + 1);
                            }
                          }}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="kt_app_footer" className="app-footer">
                <div className="app-container container-fluid d-flex justify-content-center flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      href="#"
                      target="_blank"
                      className="text-gray-800 text-hover-primary"
                    >
                      Dolibo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <i className="ki-duotone ki-arrow-up">
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
    </>
  );
};

export default ShowProject;
