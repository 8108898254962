import React from 'react';
import profile from '../../../images/profile.png';
import { useNavigate } from 'react-router-dom';

const UserNavbar = () => {
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem('dolibo_user'));
  let user1 = (user.name);
  let user2 = user1.slice(0, 2).toUpperCase();

  return (
    <>
      <div id="kt_app_header" className="app-header">
        <div
          className="app-container apps-conti container-fluid d-flex align-items-stretch justify-content-between"
          id="kt_app_header_container"
        >
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
            title="Show sidebar menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px"
              id="kt_app_sidebar_mobile_toggle"
            >
              {/* <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                <span className="path1" />
                <span className="path2" />
              </i> */}
              <i className="fa-solid fa-bars"></i>

            </div>
          </div>
          <div
            className="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
            id="kt_app_header_wrapper"
          >
            <div className="app-navbar flex-shrink-0">
              <div className="app-navbar-item ms-1 ms-md-3">

                {/* <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12.3333C12 11.2725 11.5786 10.255 10.8284 9.5049C10.0783 8.75475 9.06087 8.33332 8 8.33332C6.93913 8.33332 5.92172 8.75475 5.17157 9.5049C4.42143 10.255 4 11.2725 4 12.3333C4 17 2 18.3333 2 18.3333H14C14 18.3333 12 17 12 12.3333Z" stroke="#637381" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.15335 21C9.03614 21.2021 8.86791 21.3698 8.6655 21.4864C8.46309 21.6029 8.2336 21.6643 8.00001 21.6643C7.76643 21.6643 7.53694 21.6029 7.33453 21.4864C7.13212 21.3698 6.96389 21.2021 6.84668 21" stroke="#637381" strokeLinecap="round" strokeLinejoin="round" />
                  <circle cx="14" cy="6" r="6" fill="#DC3545" />
                  <path d="M14.492 9V7.83H11.387V6.912L14.339 2.7H15.644V6.831H16.472V7.83H15.644V9H14.492ZM12.62 6.831H14.564V3.969L12.62 6.831Z" fill="white" />
                </svg> */}

                {/* <a
                  href="#"
                  className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                  data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <i className="ki-duotone ki-night-day theme-light-show fs-2 fs-lg-1">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                  </i>
                  <i className="ki-duotone ki-moon theme-dark-show fs-2 fs-lg-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </a>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                  data-kt-menu="true"
                  data-kt-element="theme-mode-menu"
                >
                  <div className="menu-item px-3 my-0">
                    <a
                      href="#"
                      className="menu-link px-3 py-2 active"
                      data-kt-element="mode"
                      data-kt-value="light"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-duotone ki-night-day fs-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                          <span className="path6" />
                          <span className="path7" />
                          <span className="path8" />
                          <span className="path9" />
                          <span className="path10" />
                        </i>
                      </span>
                      <span className="menu-title">Light</span>
                    </a>
                  </div>
                  <div className="menu-item px-3 my-0">
                    <a
                      href="#"
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value="dark"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-duotone ki-moon fs-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </span>
                      <span className="menu-title">Dark</span>
                    </a>
                  </div>
                </div> */}
              </div>
              <div
                className="app-navbar-item ms-1 ms-md-3"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  {/* <img
                    src={profile}
                    alt="user"
                  /> */}
                  <div className='pupmkin'>
                    <span>{(user.img && user.img.url) ? <img className='rafg1' src={user.img.url}  /> : user2}</span>
                  </div>

                </div>
                <div
                  id='slink'
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        {/* <img
                          alt="Logo"
                          src={profile}
                        /> */}
                        <div className='pupmkin'>
                          <span>{(user.img && user.img.url) ? <img className='rafg1' src={user.img.url}  /> : user2}</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                          User
                        </div>
                        <a
                          href="#"
                          className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                          {/* user@user.com */}
                          {user?.name}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="menu-item px-5">
                    <div
                      className="menu-link px-5"
                      onClick={() => {
                        localStorage.removeItem('dolibo_user');
                        localStorage.removeItem('dolibo_token');
                        navigate('/signin');
                      }}
                    >
                      Sign Out
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="app-navbar-item d-lg-none ms-2 me-n2"
                title="Show header menu"
              >
                <div
                  className="btn btn-flex btn-icon btn-active-color-primary w-30px h-30px"
                  id="kt_app_header_menu_toggle"
                >
                  <i className="ki-duotone ki-element-4 fs-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserNavbar