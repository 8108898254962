import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Util = () => {
    const location=useLocation();

    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }
  
    useEffect(() => {
      loadScript("/assets/js/temp.js");
      // loadScript("https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js");
      loadScript("https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.13.2/jquery-ui.min.js");
      loadScript("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js");
      loadScript("https://cdn.amcharts.com/lib/5/index.js")
      loadScript("https://cdn.amcharts.com/lib/5/xy.js")
      loadScript("https://cdn.amcharts.com/lib/5/percent.js")
      loadScript("https://cdn.amcharts.com/lib/5/radar.js")
      loadScript("https://cdn.amcharts.com/lib/5/themes/Animated.js")
      loadScript("https://cdn.amcharts.com/lib/5/map.js")
      loadScript("https://cdn.amcharts.com/lib/5/geodata/worldLow.js")
      loadScript("https://cdn.amcharts.com/lib/5/geodata/continentsLow.js")
      loadScript("https://cdn.amcharts.com/lib/5/geodata/usaLow.js")
      loadScript("https://cdn.amcharts.com/lib/5/geodata/worldTimeZonesLow.js")
      loadScript("https://cdn.amcharts.com/lib/5/geodata/worldTimeZoneAreasLow.js");
      loadScript("/assets/plugins/global/plugins.bundle.js");
      loadScript("/assets/js/scripts.bundle.js");
      // loadScript("/assets/js/cdnjs/form-builder.min.js");
      loadScript("/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js");
      loadScript("/assets/plugins/custom/datatables/datatables.bundle.js");
      // loadScript("/assets/js/summernote-bs4.min.js");
      // loadScript("/assets/js/widgets.bundle.js");
      // loadScript("/assets/js/custom/widgets.js");
  
      // <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
      {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script> */ }
      // loadScript("/assets/js/custom/apps/chat/chat.js");
      // loadScript("/assets/js/custom/utilities/modals/upgrade-plan.js");
      // loadScript("/assets/js/custom/utilities/modals/create-app.js");
      // loadScript("/assets/js/custom/utilities/modals/new-target.js");
      // loadScript("/assets/js/custom/utilities/modals/users-search.js");
      // loadScript("/assets/js/custom/landing.js");
  
    }, [location.pathname]);

    return (
        <>

        </>
    );
};

export default Util;
